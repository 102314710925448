<template>
  <form class="form form:repairs" method="post" @submit.prevent="submit">
    <div class="form-group">
      <Employee label="Tester" v-model="tester" />
      <Employee label="Observer" v-model="observerProxy" />
    </div>

    <div class="form-group mt-1">
      <Input label="Seam ID" v-model="seamProxy" />
      <Input label="Panel(s)" v-model="panelProxy" />
      <Input label="Location" v-model="locationProxy" />
    </div>

    <div class="form-group mt-1">
      <Input label="Repair Number" v-model="repair_numberProxy" />
      <Input label="Type" v-model="repair_typeProxy" />
      <Input label="Size" v-model="repair_sizeProxy" />
    </div>

    <Date
      class="repairs-repair-date"
      label="Repair Date"
      v-model="repair_dateProxy"
    />

    <div class="field">
      <label>Description of Damage</label>
      <select :class="{ 'has-value': !!damageProxy }" v-model="damageProxy">
        <option value="" selected disabled>Select Type</option>
        <option value="BO">Burn Out</option>
        <option value="CS">Cross Seam</option>
        <option value="DS">Destructive Sample</option>
        <option value="SD">Shipping Damage</option>
        <option value="IS">Install Damage</option>
        <option value="DBO">Damage by Others</option>
        <option value="other">Other</option>
      </select>
    </div>

    <Text
      v-if="damageProxy === 'other'"
      v-model="damage_otherProxy"
      placeholder="Please describe damage"
    />

    <div class="form-group mt-1">
      <!-- <Checkbox label="Vacumm or Spark" v-model="vacuum_sparkProxy" /> -->
      <VSSwitch class="welds-welder" v-model="vacuum_sparkProxy" />
      <Checkbox label="Test Passed" v-model="vacuum_spark_passProxy" />
    </div>

    <div class="form-group">
      <Date class="placement-date" label="Date" v-model="test_dateProxy" />
      <Time label="Time" v-model="test_timeProxy" :date="new Date()" />
    </div>

    <Text placeholder="Comment" v-model="notesProxy" />

    <Button type="submit" :disabled="disabled">
      Save Entry
    </Button>
  </form>
</template>

<script>
import { required, requiredIf } from "@vuelidate/validators";
import { REPAIRS } from "@/constants";
import { clone } from "@/utils";
import _ from "lodash";

export default {
  data() {
    return {
      tester: this.$store.state.user.id,
      observer: null,
      seam: null,
      panel: null,
      location: null,
      repair_number: null,
      repair_type: null,
      repair_size: null,
      repair_date: null,
      damage: "",
      damage_other: "",
      vacuum_spark: "vacuum",
      vacuum_spark_pass: false,
      test_date: null,
      test_time: null,
      notes: null
    };
  },

  validations() {
    return {
      tester: {
        required
      },
      seam: {
        required
      },
      panel: {
        required
      },
      location: {
        required
      },
      repair_number: {
        required
      },
      repair_type: {
        required
      },
      repair_date: {
        required
      },
      damage: {
        required
      },
      damage_other: {
        requiredIf: requiredIf(() => this.damage === "other")
      }
    };
  },

  // computed and updated are the same between all forms.
  // is there a better way to make this more DRY?
  computed: {
    isEdit: {
      get() {
        return !_.isEmpty(this.$store.state.entry);
      },
      set(val) {
        return val;
      }
    },
    observerProxy: {
      get() {
        let eo =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.observer
            ? this.$store.state.entry.content.observer
            : this.observer;
        this.observer = eo; // eslint-disable-line
        return eo;
      },
      set(val) {
        this.observer = val;
      }
    },
    seamProxy: {
      get() {
        let es =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.seam
            ? this.$store.state.entry.content.seam
            : this.seam;
        this.seam = es; // eslint-disable-line
        return es;
      },
      set(val) {
        this.seam = val;
      }
    },
    panelProxy: {
      get() {
        let ep =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.panel
            ? this.$store.state.entry.content.panel
            : this.panel;
        this.panel = ep; // eslint-disable-line
        return ep;
      },
      set(val) {
        this.panel = val;
      }
    },
    locationProxy: {
      get() {
        let el =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.location
            ? this.$store.state.entry.content.location
            : this.location;
        this.location = el; // eslint-disable-line
        return el;
      },
      set(val) {
        this.location = val;
      }
    },
    repair_numberProxy: {
      get() {
        let ern =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.repair_number
            ? this.$store.state.entry.content.repair_number
            : this.repair_number;
        this.repair_number = ern; // eslint-disable-line
        return ern;
      },
      set(val) {
        this.repair_number = val;
      }
    },
    repair_typeProxy: {
      get() {
        let ert =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.repair_type
            ? this.$store.state.entry.content.repair_type
            : this.repair_type;
        this.repair_type = ert; // eslint-disable-line
        return ert;
      },
      set(val) {
        this.repair_type = val;
      }
    },
    repair_sizeProxy: {
      get() {
        let ers =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.repair_size
            ? this.$store.state.entry.content.repair_size
            : this.repair_size;
        this.repair_size = ers; // eslint-disable-line
        return ers;
      },
      set(val) {
        this.repair_size = val;
      }
    },
    repair_dateProxy: {
      get() {
        let erd =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.repair_date
            ? this.$store.state.entry.content.repair_date
            : this.repair_date;
        this.repair_date = erd; // eslint-disable-line
        return erd;
      },
      set(val) {
        this.repair_date = val;
      }
    },
    damageProxy: {
      get() {
        let ed =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.damage
            ? this.$store.state.entry.content.damage
            : this.damage;
        this.damage = ed; // eslint-disable-line
        return ed;
      },
      set(val) {
        this.damage = val;
      }
    },
    damage_otherProxy: {
      get() {
        let edo =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.damage === "other" &&
          this.$store.state.entry.content.damage_other
            ? this.$store.state.entry.content.damage_other
            : this.damage_other;
        this.damage_other = edo; // eslint-disable-line
        return edo;
      },
      set(val) {
        this.damage_other = val;
      }
    },
    vacuum_sparkProxy: {
      get() {
        let evs =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.vacuum_spark
            ? this.$store.state.entry.content.vacuum_spark
            : this.vacuum_spark;
        this.vacuum_spark = evs; // eslint-disable-line
        return evs;
      },
      set(val) {
        this.vacuum_spark = val;
      }
    },
    vacuum_spark_passProxy: {
      get() {
        let evsp =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.vacuum_spark_pass
            ? this.$store.state.entry.content.vacuum_spark_pass
            : this.vacuum_spark_pass;
        this.vacuum_spark_pass = evsp; // eslint-disable-line
        return evsp;
      },
      set(val) {
        this.vacuum_spark_pass = val;
      }
    },
    test_dateProxy: {
      get() {
        let td =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.test_date
            ? this.$store.state.entry.content.test_date
            : this.test_date;
        this.test_date = td; // eslint-disable-line
        return td;
      },
      set(val) {
        this.test_date = val;
      }
    },
    test_timeProxy: {
      get() {
        let tt =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.test_time
            ? this.$store.state.entry.content.test_time
            : this.test_time;
        this.test_time = tt; // eslint-disable-line
        return tt;
      },
      set(val) {
        this.test_time = val;
      }
    },
    notesProxy: {
      get() {
        let en =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.notes
            ? this.$store.state.entry.content.notes
            : this.notes;
        this.notes = en; // eslint-disable-line
        return en;
      },
      set(val) {
        this.notes = val;
      }
    },
    disabled() {
      // return this.isEdit ? false : !this.$v.$dirty || this.$v.$errors.length;
      return false;
    }
  },
  updated() {
    this.$v.$touch();
  },

  methods: {
    submit() {
      var vm = this;
      let data = clone(this.$data);
      data.edit = this.isEdit;
      if (data.damage !== "other") delete data.damage_other;
      data.type = REPAIRS;
      //if (this.isEdit) data.tempId = this.$store.state.entry.date;
      this.$store.dispatch("entry", data).then(() => {
        vm.$store.commit("clearEntry");
      });
    }
  }
  // created() {
  //   console.log("edit entry", this.$store.state.entry);
  // }
};
</script>

<style lang="postcss">
.form\:repairs {
  & .repairs-repair-date {
    max-width: 375px;
  }
}
</style>
