<template>
  <div class="subgradesignoff">
    <h4 class="c-gray-7 mb-2">{{ today }}</h4>
    <p v-if="layer.type === 'cover'">
      I, the Undersigned, the duly authorized representative of Industrial &amp;
      Environmental Concepts do hereby accept the soil subgrade surface is
      suitable for the installation of the specified geosynthetic materials.
    </p>

    <form
      class="form form:signoff mt-5"
      method="post"
      @submit.prevent="onSubmit"
    >
      <fieldset>
        <Text label="Area to be covered" v-model="area" />
      </fieldset>

      <fieldset class="mb-4" v-show="!bypass">
        <div class="form-group">
          <Input label="Customer Representative" v-model="customer.name" />
          <Input label="Title" v-model="customer.title" />
        </div>
        <Text placeholder="Comments" v-model="customer.comments" />
        <Signature
          :data="customer.signature"
          @signed="onSigned('customer', $event)"
        />
      </fieldset>

      <fieldset>
        <div class="form-group">
          <Input label="IEC Representative" v-model="employee.name" />
          <Input label="Title" v-model="employee.title" />
        </div>
        <Text
          :placeholder="bypass ? 'Reason for bypass' : 'Comments'"
          v-model="employee.comments"
        />
        <Signature
          :data="employee.signature"
          @signed="onSigned('employee', $event)"
        />
      </fieldset>

      <Checkbox label="Bypass customer sign-off" v-model="bypass" />

      <Button type="submit" :disabled="disabled">
        <span>Submit Daily Sign-off</span>
      </Button>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import dayjs from "dayjs";
import { SUBGRADESIGNOFF } from "@/constants";

export default {
  data() {
    const user = this.$store.state.user;

    const area = null;

    const customer = {
      name: null,
      title: null,
      company: null, // TODO: this should come from the project state
      comments: null,
      signature: null
    };

    const employee = {
      name: user.name,
      title: user.role,
      // company: 'Environmental & Industrial Concepts, Inc',
      comments: null,
      signature: null
    };

    return {
      customer,
      employee,
      area,
      bypass: false
    };
  },

  computed: {
    ...mapState(["user"]),
    // TODO: does this need to be a getter?
    ...mapGetters(["layer"]),
    today() {
      return dayjs().format("MMMM D, YYYY");
    },
    disabled() {
      return (
        !this.employee.signature ||
        (!this.employee.comments && this.bypass) ||
        (!this.customer.signature && !this.bypass)
      );
    }
  },

  methods: {
    onSigned(who, image) {
      this[who].signature = image;
    },

    onSubmit() {
      this.$store.dispatch("entry", {
        type: SUBGRADESIGNOFF,
        bypassed: this.bypass,
        area: this.area,
        customer: this.bypass ? {} : { ...this.customer },
        employee: { ...this.employee }
      });
    }
  }
};
</script>

<style lang="postcss">
.sheet-content .subgradesignoff {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}
.form\:signoff {
  & fieldset {
    display: grid;
    grid-gap: var(--form-group-gap);
  }
}
</style>
