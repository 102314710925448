<template>
  <svg hidden>
    <symbol id="icon-layers" viewBox="0 0 17 17">
      <path
        d="M1.375 5.15625L8.65625 8.4375C8.875 8.53125 9.09375 8.53125 9.3125 8.4375L16.5938 5.15625C17.125 4.90625 17.125 4.125 16.5938 3.875L9.3125 0.59375C9.21875 0.53125 9.09375 0.53125 9 0.53125C8.875 0.53125 8.75 0.53125 8.65625 0.59375L1.375 3.875C0.84375 4.125 0.84375 4.90625 1.375 5.15625ZM16.5938 7.90625L14.7812 7.0625L9.71875 9.375C9.5 9.46875 9.25 9.53125 9 9.53125C8.71875 9.53125 8.46875 9.46875 8.25 9.375L3.1875 7.0625L1.375 7.90625C0.84375 8.125 0.84375 8.90625 1.375 9.15625L8.65625 12.4375C8.875 12.5312 9.09375 12.5312 9.3125 12.4375L16.5938 9.15625C17.125 8.90625 17.125 8.125 16.5938 7.90625ZM16.5938 11.9062L14.7812 11.0625L9.71875 13.375C9.5 13.4688 9.25 13.5312 9 13.5312C8.71875 13.5312 8.46875 13.4688 8.25 13.375L3.1875 11.0625L1.375 11.9062C0.84375 12.125 0.84375 12.9062 1.375 13.1562L8.65625 16.4375C8.875 16.5312 9.09375 16.5312 9.3125 16.4375L16.5938 13.1562C17.125 12.9062 17.125 12.125 16.5938 11.9062Z"
        fill="currentColor"
      />
    </symbol>

    <symbol id="icon-play" viewBox="0 0 17 20">
      <path
        d="M16.1163 8.37494L2.73672 0.255321C1.63443 -0.408301 0 0.255321 0 1.85582V18.0951C0 19.5784 1.5204 20.4763 2.73672 19.7346L16.1163 11.615C17.2946 10.8733 17.2946 9.11663 16.1163 8.37494Z"
        fill="currentColor"
      />
    </symbol>

    <symbol id="icon-arrow-left" viewBox="0 0 22 22">
      <path
        d="M11.23 21.22l.94-.9c.24-.23.24-.6 0-.79l-7.26-7.31h16.03c.28 0 .56-.24.56-.56v-1.32a.6.6 0 00-.56-.56H4.9l7.26-7.26c.24-.2.24-.57 0-.8l-.94-.9c-.18-.23-.56-.23-.8 0l-9.79 9.8a.54.54 0 000 .8l9.8 9.8c.23.23.6.23.8 0z"
      />
    </symbol>

    <symbol id="icon-check" viewBox="0 0 512 512">
      <path
        d="M173.9 439.4L7.5 273c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 312.69l240.1-240.1c10-10 26.2-10 36.2 0l36.2 36.21c10 10 10 26.2 0 36.2L210.1 439.4c-10 10-26.2 10-36.2 0z"
      />
    </symbol>

    <symbol id="icon-plus" viewBox="0 0 31 30">
      <path
        d="M29.45 12.5H18.2V1.25C18.2.62 17.58 0 16.95 0h-2.5c-.7 0-1.25.63-1.25 1.25V12.5H1.95c-.7 0-1.25.63-1.25 1.25v2.5c0 .7.55 1.25 1.25 1.25H13.2v11.25c0 .7.55 1.25 1.25 1.25h2.5c.63 0 1.25-.55 1.25-1.25V17.5h11.25c.63 0 1.25-.55 1.25-1.25v-2.5c0-.63-.62-1.25-1.25-1.25z"
      />
    </symbol>

    <symbol id="icon-fail" viewBox="0 0 352 512">
      <path
        d="M242.72 256l100.07-100.07a31.46 31.46 0 000-44.48l-22.24-22.24a31.46 31.46 0 00-44.48 0L176 189.28 75.93 89.21a31.46 31.46 0 00-44.48 0L9.21 111.45a31.46 31.46 0 000 44.48L109.28 256 9.21 356.07a31.46 31.46 0 000 44.48l22.24 22.24a31.46 31.46 0 0044.48 0L176 322.72l100.07 100.07a31.46 31.46 0 0044.48 0l22.24-22.24a31.46 31.46 0 000-44.48L242.72 256z"
      />
    </symbol>

    <symbol id="icon-close" viewBox="0 0 320 512">
      <path
        d="M193.94 256L296.5 153.44l21.15-21.15a8 8 0 000-11.31l-22.63-22.63a8 8 0 00-11.31 0L160 222.06 36.29 98.34a8 8 0 00-11.31 0L2.34 120.97a8 8 0 000 11.31L126.06 256 2.34 379.71a8 8 0 000 11.31l22.63 22.63a8 8 0 0011.31 0L160 289.94 262.56 392.5l21.15 21.15a8 8 0 0011.31 0l22.63-22.63a8 8 0 000-11.31L193.94 256z"
      />
    </symbol>

    <symbol id="icon-lock" viewBox="0 0 26 33">
      <path
        fill-rule="evenodd"
        d="M13 0a8.12 8.12 0 018.13 8.13V13h1.62c1.74 0 3.25 1.26 3.25 2.98v13.54c0 1.72-1.51 2.98-3.25 2.98H3.25C1.51 32.5 0 31.24 0 29.52V15.98C0 14.26 1.51 13 3.25 13h1.63V8.12A8.13 8.13 0 0113 0zm9.75 16.25H3.25v13h19.5v-13zM13 3.25a4.87 4.87 0 00-4.88 4.88V13h9.76V8.12c0-2.69-2.19-4.87-4.88-4.87zm0 16.47c.74 0 1.34.6 1.34 1.35v3.59a1.34 1.34 0 11-2.68 0v-3.6c0-.73.6-1.34 1.34-1.34z"
      />
    </symbol>
  </svg>
</template>
