import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import axios from "axios";
import db from "@/db";
import router from "@/router";
import api from "@/api";
import _ from "lodash";

const status = Object.freeze({
  LOADING: "loading",
  ERROR: "error",
  SUCCESS: "success"
});

const store = createStore({
  plugins: [createPersistedState()],
  strict: process.env.NODE_ENV !== "production",

  state: {
    token: null,
    status: "",
    user: {},
    users: [],
    projects: [],
    project: null,
    project_users: [],
    site: null,
    layer: null,
    entries: [],
    entry: {},
    thresholds: {},
    materials: {}
  },

  getters: {
    isAuthenticated(state) {
      return state.token !== null;
    },
    status(state) {
      return state.status;
    },
    token(state) {
      return state.token;
    },
    hasProjects: state => {
      return !_.isEmpty(state.projects.projects);
    },
    hasProject: state => {
      return !_.isEmpty(state.project);
    },
    hasSites: state => {
      return !_.isEmpty(state.project);
    },
    project_users: state => {
      return state.project_users;
    },
    project: state => {
      return state.project;
    },
    layer: state => {
      return state.layer;
    },
    thresholds: state => {
      return state.thresholds;
    },
    entry: state => {
      return state.entry;
    },
    hasEntries: state => {
      return !_.isEmpty(state.entries);
    },
    entries: state => {
      const entries = [...state.entries];
      entries.reverse();
      return entries;
    },
    hasMaterials: state => {
      return !_.isEmpty(state.materials);
    },
    materials: state => {
      return state.materials;
    },
    connected: () => {
      let { onLine, connection } = window.navigator;
      let isReliable = ["3g", "4g"].includes(connection.effectiveType);
      return onLine && isReliable;
    }
  },

  actions: {
    authenticate(context, code) {
      context.commit("setStatus", status.LOADING);
      api.activate(code).then(result => {
        if (result.success && result.token) {
          localStorage.setItem("token", result.token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${result.token}`;
          context.commit("setToken", result.token);
          context.commit("setStatus", status.SUCCESS);
          context.dispatch("getUsers");
          router.push({ name: "login" });
        } else {
          context.commit("setStatus", status.ERROR);
        }
      });
    },
    getUsers(context) {
      api.getUsers().then(users => {
        context.commit("setUsers", users);
      });
    },
    getUserInitals({ state }, id) {
      if (_.isUndefined(id)) {
        return "?";
      } else {
        let user = state.users.find(obj => {
          return obj.id === parseInt(id);
        });
        return _.cloneDeep(user.initials);
      }
    },
    async getMaterials(context) {
      api.getMaterials().then(materials => {
        // console.log("store materials", materials);
        context.commit("setMaterials", _.cloneDeep(materials));
      });
    },
    getProjects(context) {
      api.getProjects(context.state.user.id).then(projects => {
        context.commit("setThresholds", projects.thresholds);
        context.commit("setProjects", projects);
      });
    },
    async getProjectById(context, id) {
      const project = await (await db).get("projects", parseInt(id));
      context.commit("resetProject");
      context.commit("setProject", _.cloneDeep(project));
    },
    resetProject(context) {
      context.commit("resetProject");
    },
    getSite({ commit, state }, payload) {
      let site = state.project.sites.find(obj => {
        return obj.name === payload;
      });
      commit("setSite", site);
    },
    setSite({ commit }, payload) {
      for (let s = 0; s < payload.length; s++) {
        var layers = payload[s].layers;
        for (let l = 0; l < layers.length; l++) {
          delete layers[l].entries;
        }
      }
      commit("setSite", payload);
    },
    getLayer({ commit, state }, payload) {
      let site = state.project.sites.find(obj => {
        return obj.id === parseInt(state.site.id);
      });
      let layer = site.layers.find(obj => {
        return obj.id === parseInt(payload);
      });
      commit("setLayer", layer);
    },
    updateSelectedUser({ commit }, payload) {
      commit("updateSelectedUser", payload);
    },
    setUser({ commit }, payload) {
      commit("setUser", payload);
      router.push({ name: "projects" });
    },
    signOutUser({ commit }) {
      router.push({ name: "login" });
      commit("signOut");
    },
    async login(context, pin) {
      const user = await (await db).get("users", pin);
      if (!user) return Promise.reject();
      context.commit("update", {
        name: "user",
        data: user
      });
      return Promise.resolve();
    },
    async updateProjectDB(context) {
      const projects = [];
      projects.push(_.cloneDeep(context.state.project));
      const pid = _.cloneDeep(parseInt(context.state.project.id));
      const em = (await db).transaction("projects", "readwrite");
      await Promise.all([
        ...projects.map(row => em.store.put({ ...row, id: pid })),
        em.done
      ]);
    },
    async entries(context, type) {
      const layer = Number(router.currentRoute.value.params.layer);
      const entries = await (await db).getAllFromIndex("entries", "type", [
        layer,
        type
      ]);
      // console.log("DB entries", entries);
      //
      // let lsEntries = context.state.layer.entries.filter(function (e) {
      //   return e.type === type;
      // });
      // console.log('LS entries', _.cloneDeep(lsEntries));
      // // if content.date matches only keep entry with
      // // console.log(Object.values(entries.reduce((acc,cur)=>Object.assign(acc,{[cur.content.date]:cur}),{})));
      // let deduped = Object.values(_.cloneDeep(lsEntries).reduce((acc,cur)=>Object.assign(acc,{[cur.content.date]:cur}),{}));
      // console.log('deduped', deduped);

      context.commit("update", {
        name: "entries",
        data: entries
      });
    },
    async entry(context, payload) {
      // console.log("entry payload", payload);
      let originalDate = !_.isEmpty(context.state.entry)
        ? context.state.entry.content.date
        : Date.now();
      // console.log("originalDate", originalDate);
      const params = router.currentRoute.value.params;
      payload.date = payload.date ? payload.date : Date.now();
      payload.saved = 0;
      let entry = {
        layer_id: Number(params.layer),
        employee_id: context.state.user.id,
        type: payload.type,
        date: Date.now(),
        saved: 0,
        pending: true,
        tempId: originalDate,
        content: payload
      };
      // console.log("entry - before", entry);
      entry.content.date = originalDate;
      // console.log("entry - after", entry);
      let updateEntry = entry;
      updateEntry.project_id = context.state.project.id;
      updateEntry.site_id = context.state.site.id;
      // if (payload.tempId) updateEntry.tempId = payload.tempId;
      if (payload.edit === true) {
        context.commit("modExistingEntry", updateEntry);
      } else {
        context.commit("updateProjectEntries", updateEntry);
      }
      (await db).add("entries", entry);
      context.dispatch("push");
      context.dispatch("updateProjectDB");
    },
    async voidEntry(context, payload) {
      // payload.date = payload.date ? payload.date : Date.now();
      payload.date = Date.now();
      payload.saved = 0;
      payload.pending = true;
      payload.employee_id = context.state.user.id;
      payload.tempId = payload.content.date;
      // payload.edit === true
      context.commit("modExistingEntry", payload);
      (await db).add("entries", payload);
      context.dispatch("push");
      context.dispatch("updateProjectDB");
    },
    async push(context) {
      const now = Date.now();
      // bail if it hasn't been 30 minutes since last save attempt
      const interval = 1000 * 60 * 30; // 30 minutes in milliseconds
      const saved = window.localStorage.getItem("saved_at") || 0;
      if (now - saved < interval) {
        return;
      }
      window.localStorage.setItem("saved_at", now);
      // bail if insufficient connectivity
      // and try again in another 30 minutes
      if (context.getters.connected === false) {
        return;
      }
      // it's been 30 minutes since the last save,
      // attempt to push any unsaved entires
      const entries = await (await db).getAllFromIndex("entries", "saved", 0);
      if (_.isEmpty(entries)) {
        return;
      }
      api
        .postEntries(entries)
        .then(result => {
          result.forEach(item => {
            context.commit("updatePendingEntry", item);
          });
        })
        .finally(() => {
          context.dispatch("updateProjectDB");
        });
    },
    async pushNow(context) {
      const now = Date.now();
      // bypass 30 minutes wait time
      window.localStorage.setItem("saved_at", now);
      // bail if insufficient connectivity
      if (context.getters.connected === false) {
        return;
      }
      const entries = await (await db).getAllFromIndex("entries", "saved", 0);
      if (_.isEmpty(entries)) {
        return;
      }
      // console.log("pushNow", entries);
      await api
        .postEntries(entries)
        .then(result => {
          result.forEach(item => {
            context.commit("updatePendingEntry", item);
          });
        })
        .finally(() => {
          context.dispatch("updateProjectDB");
        });
    },
    async getPendingEntries() {
      const entries = await (await db).getAllFromIndex("entries", "saved", 0);
      if (!_.isEmpty(entries)) {
        return _.cloneDeep(entries);
      }
    },
    async getSavedEntries() {
      const entries = await (await db).getAllFromIndex("entries", "saved", 1);
      if (!_.isEmpty(entries)) {
        return _.cloneDeep(entries);
      }
    },
    async getAllEntries() {
      const entries = await (await db).getAllFromIndex("entries", "type");
      if (!_.isEmpty(entries)) {
        return _.cloneDeep(entries);
      }
    }
  },

  mutations: {
    update: (state, { name, data }) => (state[name] = data),
    setStatus(state, status) {
      state.status = status;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUsers(state, users) {
      state.users = _.cloneDeep(users);
    },
    setThresholds(state, thresholds) {
      state.thresholds = _.cloneDeep(thresholds);
    },
    setMaterials(state, materials) {
      state.materials = _.cloneDeep(materials);
    },
    setProjects(state, projects) {
      delete projects.thresholds;
      for (let p = 0; p < projects.projects.length; p++) {
        let str = JSON.stringify(projects.projects[p]);
        projects.projects[p].layerCount = (str.match(/material/g) || []).length;
        delete projects.projects[p].sites;
      }
      state.projects = projects;
    },
    setProject(state, payload) {
      state.project = _.cloneDeep(payload);
      let sites = _.cloneDeep(payload.sites);
      for (let s = 0; s < sites.length; s++) {
        var layers = sites[s].layers;
        for (let l = 0; l < layers.length; l++) {
          delete layers[l].entries;
        }
      }
      state.sites = sites;
      state.site = sites[0];
      state.project_users = [];
      _.forEach(payload.users, function(val) {
        let user = state.users.find(obj => {
          return obj.id === parseInt(val);
        });
        state.project_users.push(user);
      });
    },
    resetProject(state) {
      state.project = null;
      state.project_users = [];
      state.sites = null;
      state.site = null;
      state.layer = null;
    },
    updateProjectEntries(state, payload) {
      // console.log("updateProjectEntries", payload);
      let sidx = _.findIndex(state.project.sites, { id: payload.site_id });
      let lidx = _.findIndex(state.project.sites[sidx].layers, {
        id: payload.layer_id
      });
      state.project.sites[sidx].layers[lidx].entries.unshift(payload);
      state.layer.entries.unshift(payload);
      if (payload.type === "signoff") {
        router.push({
          name: "project",
          params: { project: state.project.id }
        });
      }
      if (payload.type === "complete") {
        state.project.sites[sidx].layers[lidx].is_completed = true;
        state.sites[sidx].layers[lidx].is_completed = true;
        state.site.layers[lidx].is_completed = true;
        state.layer.is_completed = true;
        router.push({
          name: "project",
          params: { project: state.project.id }
        });
      }
    },
    modExistingEntry(state, payload) {
      // console.log("modExistingEntry", payload);
      let sidx = _.findIndex(state.project.sites, { id: payload.site_id });
      let lidx = _.findIndex(state.project.sites[sidx].layers, {
        id: payload.layer_id
      });
      let eidx = _.findIndex(
        state.project.sites[sidx].layers[lidx].entries,
        function(item) {
          return item.content.date == payload.tempId;
        }
      );
      state.project.sites[sidx].layers[lidx].entries.splice([eidx], 1, payload);
      state.layer.entries.splice([eidx], 1, payload);
    },
    updatePendingEntry(state, payload) {
      // console.log("updatePendingEntry", payload);
      delete payload.pending;
      let sidx = _.findIndex(state.project.sites, { id: payload.site_id });
      let lidx = _.findIndex(state.project.sites[sidx].layers, {
        id: payload.layer_id
      });
      // let eidx = _.findIndex(state.project.sites[sidx].layers[lidx].entries, {
      //   date: payload.content.date
      // });
      let eidx = _.findIndex(
        state.project.sites[sidx].layers[lidx].entries,
        function(item) {
          return item.content.date == payload.content.date;
        }
      );
      // console.log("eidx", eidx);
      state.project.sites[sidx].layers[lidx].entries.splice([eidx], 1, payload);
      state.layer.entries.splice([eidx], 1, payload);
    },
    setEntry(state, payload) {
      let editEntry = _.cloneDeep(payload);
      editEntry.edit = true;
      state.entry = editEntry;
    },
    clearEntry(state) {
      state.entry = {};
    },
    setSite(state, payload) {
      state.site = _.cloneDeep(payload);
    },
    setLayer(state, payload) {
      state.layer = _.cloneDeep(payload);
    },
    updateSelectedUser(state, id) {
      state.user.id = id;
    },
    setUser(state, payload) {
      state.user = _.cloneDeep(payload[0]);
    },
    signOut(state) {
      state.user = {};
      state.projects = [];
      state.project = null;
      state.project_users = [];
      state.sites = null;
      state.site = null;
      state.layer = null;
    }
  }
});

export default store;
