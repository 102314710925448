<template>
  <!-- <component :is="element" :type="element === 'button' && type" :href="href" class="button">
    <slot />
  </component> -->
  <button :type="type" class="button">
    <slot />
  </button>
</template>

<script>
const types = ["button", "submit"];

export default {
  props: {
    href: {
      type: String
    },
    type: {
      type: String,
      default: "button",
      validator: value => types.includes(value)
    }
  },
  computed: {
    element() {
      return this.href ? "a" : "button";
    }
  }
};
</script>

<style>
.button {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.33em 2em;
  background-color: var(--color-green-2);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-md);
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.25s;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, #000, #fff);
    opacity: 0.06;
    transition: inherit;
    mix-blend-mode: overlay;
  }
  &:focus,
  &:active {
    background-color: var(--color-green-1);
    box-shadow: none;
  }
  &:disabled {
    background-color: var(--color-gray-4);
    pointer-events: none;
    box-shadow: none;
  }
  &:disabled:before {
    opacity: 0;
  }
}
</style>
