<template>
  <header class="header pad">
    <button type="button" @click="goBack()">
      <Icon name="arrow-left" />
    </button>

    <div class="main" v-if="project">
      <Marker
        v-if="project.type && project.color"
        :type="project.type"
        :color="project.color"
      />
      <ol>
        <li>{{ project.client }}</li>
        <li>{{ project.location }}</li>
      </ol>
    </div>

    <button type="button" @click="$refs.modalName.openModal()">
      <Avatar :user="user" />
    </button>
  </header>

  <logInModal ref="modalName" />
</template>

<script>
import router from "@/router";
import { mapState } from "vuex";
import logInModal from "@/components/LoginModal";
export default {
  props: {
    project: { type: Object }
  },
  components: { logInModal },
  computed: {
    // mapState(["projects"]),
    ...mapState({
      user: state => state.user
      // project: state => state.project,
      // site: state => state.site
    })
  },
  methods: {
    goBack() {
      if (this.$route.name === "project") {
        router.push({ path: "/projects/" });
      } else {
        router.push({
          name: "project",
          params: { project: this.$store.state.project.id }
        });
      }
      // window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
};
</script>

<style scoped lang="postcss">
.header {
  display: grid;
  grid-gap: var(--space-3);
  grid-template-columns: auto 1fr auto;

  & > button:first-child {
    display: flex;
    width: 2rem;
    height: 3.75rem;
    background: transparent;
  }
}

.main {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;

  & > ol > li {
    font-size: var(--text-md);
    font-weight: bold;

    &:first-of-type {
      font-size: var(--text-sm);
      color: var(--color-gray-6);
    }
  }
}
</style>
