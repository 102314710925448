<template>
  <form class="form form:placement" method="post" @submit.prevent="submit">
    <div class="form-group">
      <Employee label="Tester" role="tester" v-model="tester" />
      <Employee label="Observer" role="tester" v-model="observer" />
    </div>

    <fieldset class="my-2">
      <h4>Panel Identification</h4>

      <div class="form-group">
        <!-- <Input label="Layer" v-model="layer" /> -->
        <Input label="Panel Number" :prefix="'P-'" v-model="number" />
        <Input label="Location" v-model="location" />
      </div>
    </fieldset>

    <div class="form-group">
      <Input class="placement-roll" label="Roll Number" v-model.number="roll" />
      <Input class="placement-length" label="Length" v-model.number="length" />
      <Range class="placement-station" label="Station" v-model="station" />
    </div>

    <div class="form-group">
      <Date class="placement-date" label="Date" v-model="placement_date" />
      <Time label="Start Time" v-model="time" :date="new Date()" />
    </div>

    <Text placeholder="Comments" v-model="notes" />

    <Button type="submit" :disabled="disabled">
      Save Entry
    </Button>
  </form>
</template>

<script>
import { required, minLength } from "@vuelidate/validators";
import { PLACEMENT } from "@/constants";
import { clone } from "@/utils";

export default {
  data() {
    return {
      tester: this.$store.state.user.id,
      observer: null,
      // layer: null,
      number: null,
      location: null,
      placement_date: null,
      time: null,
      roll: null,
      length: null,
      station: [],
      notes: null
    };
  },

  validations() {
    return {
      tester: {
        required
      },
      number: {
        required
      },
      location: {
        required
      },
      roll: {
        required
      },
      length: {
        required
      },
      placement_date: {
        required
      },
      time: {
        required
      },
      station: {
        required,
        minLength: minLength(2)
      }
    };
  },

  // computed and updated are the same between all forms.
  // is there a better way to make this more DRY?
  computed: {
    disabled() {
      // return !this.$v.$dirty || this.$v.$errors.length;
      return false;
    }
  },
  updated() {
    this.$v.$touch();
  },

  methods: {
    submit() {
      const data = clone(this.$data);
      data.type = PLACEMENT;
      let prefixed = "P-" + data.number;
      data.number = prefixed;
      this.$store.dispatch("entry", data);
    }
  }
};
</script>

<style lang="postcss">
.form\:placement {
  & .placement-date {
    max-width: 375px;
  }
  & .placement-roll,
  & .placement-length,
  & .placement-station {
    max-width: calc(50% - (var(--form-group-gap) / 2));
  }
}
</style>
