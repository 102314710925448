<template>
  <form class="form form:destructive" method="post" @submit.prevent="submit">
    <div class="form-group">
      <Employee label="Tester" role="tester" v-model="tester" />
      <Employee label="Welder" role="seamer" v-model="seamer" />
    </div>

    <Switch class="welds-welder" v-model="welder" />

    <div class="form-group">
      <Input label="Sample" v-model="sample" />
      <Input label="Seam Number" v-model.number="seam" />
      <Input label="Machine" v-model.number="machine" />
    </div>

    <Tests type="peel" :welder="welder" v-model="peel" />
    <Tests type="shear" :welder="welder" v-model="shear" />

    <div class="form-group">
      <Date class="placement-date" label="Date" v-model="test_date" />
      <Time label="Time" v-model="test_time" :date="new Date()" />
    </div>

    <Text placeholder="Location" v-model="location" />
    <Text placeholder="Comments" v-model="notes" />

    <Button type="submit" :disabled="disabled">
      Save Entry
    </Button>
  </form>
</template>

<script>
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
// import { DESTRUCTIVE, MATERIALS } from "@/constants";
import { DESTRUCTIVE } from "@/constants";
import { clone } from "@/utils";
import _ from "lodash";

export default {
  data() {
    return {
      tester: this.$store.state.user.id,
      welder: "extrusion",
      seamer: null,
      sample: null,
      seam: null,
      machine: null,
      peel: [],
      shear: [],
      location: null,
      test_date: null,
      test_time: null,
      notes: null
    };
  },

  validations() {
    return {
      tester: {
        required
      },
      welder: {
        required
      },
      seamer: {
        required
      },
      sample: {
        required
      },
      seam: {
        required
      },
      machine: {
        required
      },
      peel: {
        required
      }
    };
  },

  // computed and updated are the same between all forms.
  // is there a better way to make this more DRY?
  computed: {
    ...mapGetters(["materials"]),
    disabled() {
      // return !this.$v.$dirty || this.$v.$errors.length;
      return false;
    }
  },
  updated() {
    this.$v.$touch();
  },

  methods: {
    submit() {
      // TODO: Sean would like all the values to pass. If one does not pass the entire set doesn't pass.
      const data = clone(this.$data);

      if (this.welder === "wedge") {
        delete data.extrusion_barrel;
        delete data.extrusion_preheat;
      } else {
        delete data.wedge_temp;
        delete data.wedge_speed;
      }

      let material = this.$store.state.layer.material;
      let peelMin = !_.isEmpty(this.$store.state.thresholds)
        ? this.$store.state.thresholds[material][`${data.welder}_peel`]
        : this.$store.state.materials[material][`${data.welder}_peel`];
      let peelPassed;
      if (this.welder === "wedge") {
        data.peel = data.peel.filter(a => a[1]);
        peelPassed = data.peel.every(
          value => value[0] >= peelMin && value[1] >= peelMin
        );
      } else {
        data.peel = data.peel.filter(a => a[0] !== null);
        peelPassed = data.peel.every(value => value[0] >= peelMin);
      }
      let shearMin = !_.isEmpty(this.$store.state.thresholds)
        ? this.$store.state.thresholds[material][`${data.welder}_shear`]
        : this.$store.state.materials[material][`${data.welder}_shear`];
      let shearPassed = data.shear.every(value => value >= shearMin);
      data.pass = peelPassed && shearPassed;
      data.type = DESTRUCTIVE;
      // data.pass = true;
      this.$store.dispatch("entry", data);
    }
  }
};
</script>

<style></style>
