<template>
  <div class="date_time">
    <b>Test Date/Time: </b>
    <span v-html="testDate" /> @
    <span v-html="testTime" />
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    time: {
      type: String,
      required: true
    }
  },
  // data() {
  //   return {
  //     icon: ""
  //   };
  // },
  computed: {
    testDate() {
      if (!this.date) return false;
      const date = dayjs(this.date);
      return date.format("MM/DD/YY");
    },
    testTime() {
      if (!this.time) return false;
      var modTime = this.time;
      modTime = modTime.split(":");
      // fetch
      var hours = Number(modTime[0]);
      var minutes = Number(modTime[1]);
      // calculate
      var timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = "" + hours;
      } else if (hours > 12) {
        timeValue = "" + (hours - 12);
      } else if (hours == 0) {
        timeValue = "12";
      }
      timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
      timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM
      return timeValue;
    }
  }
};
</script>
