<template>
  <div class="authenticate pad">
    <div>
      <Icon name="lock" />
    </div>

    <div class="c-green-1 fw-600">
      Industrial &amp; Environmental Concepts, Inc.
    </div>
    <h1 class="c-white">Field Documentation</h1>

    <form method="post">
      <input
        :value="code"
        @input="input"
        type="text"
        :maxlength="8"
        inputmode="text"
        placeholder="Enter Security Code"
      />
      <span class="invalid">{{
        this.status === "error" || this.invalid ? "invalid code" : "&nbsp;"
      }}</span>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    code: "",
    invalid: false
  }),
  computed: {
    ...mapState({
      status: state => state.status
    })
  },
  methods: {
    input(event) {
      const input = event.target;
      let code = (input.value = input.value.toUpperCase());
      if (code.length < input.maxLength) return;
      var vm = this;
      this.$store
        .dispatch("authenticate", code)
        .then(function() {
          if (vm.status === "success") {
            vm.router.push({ path: "/projects/" });
          }
        })
        .catch(() => {
          code = "";
          vm.invalid = true;
          input.blur();
        });
    }
  },
  mounted() {
    this.$store.commit("setStatus", "");
  }
};
</script>

<style lang="postcss">
.authenticate {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-green-4);

  & .invalid {
    color: var(--color-red-light);
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  & > div:first-child {
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: 1.5rem;
    background-color: var(--color-yellow);
    border-radius: var(--border-radius-sm);
    position: relative;
  }
  & > div:first-child svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%);
    color: var(--color-green-4);
  }

  & form {
    max-width: 300px;
    margin: 1rem auto 3rem auto;
    text-align: center;
  }
  & form input {
    font-size: 1.5rem;
    width: 100%;
    border: 0;
    text-align: center;
  }
  & form input::placeholder {
    font-weight: 400;
    color: var(--color-gray-5);
  }
  & form span {
    display: block;
    margin-top: 1em;
    font-size: var(--text-sm);
    font-weight: 600;
    color: var(--color-yellow);
  }
}
</style>
