<template>
  <div class="loading-modal">
    <div class="loading-modal__backdrop" @click="closeModal()" />
    <div class="loading-modal__dialog">
      <div class="dot-pulse" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>

<style>
.loading-modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
.loading-modal__backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.loading-modal__dialog {
  background: transparent;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  max-width: 5rem;
  height: 5rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-green-1);
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px var(--color-green-1);
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-green-1);
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px var(--color-green-1);
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px var(--color-green-1);
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px var(--color-green-1);
  }
  30% {
    box-shadow: 9984px 0 0 2px var(--color-green-1);
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--color-green-1);
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px var(--color-green-1);
  }
  30% {
    box-shadow: 9999px 0 0 2px var(--color-green-1);
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--color-green-1);
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px var(--color-green-1);
  }
  30% {
    box-shadow: 10014px 0 0 2px var(--color-green-1);
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--color-green-1);
  }
}
</style>
