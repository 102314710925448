export const noop = fn => fn;
export const isArray = Array.isArray;
export const toArray = Array.from;

export function toBoolean(value) {
  if (isBoolean(value)) {
    return value;
  }

  if (isString(value)) {
    switch (value.toLowerCase()) {
      case "undefined":
      case "false":
      case "null":
      case "no":
      case "0":
        return false;
      case "true":
      case "yes":
      case "1":
      default:
        return true;
    }
  }

  return Boolean(value);
}

export function isBoolean(value) {
  return typeof value === "boolean";
}

export function isEmpty(value) {
  if (isUndefined(value) || value === null || value === false || value === 0) {
    return true;
  }
  if (isArray(value) || isString(value)) {
    return value.length === 0;
  }
  if (isObject(value)) {
    return value.constructor === Object && Object.keys(value).length === 0;
  }
  return false;
}

export function isDefined(value) {
  return !isUndefined(value);
}

export function isFunction(value) {
  return typeof value === "function";
}

export function isNumber(value) {
  return typeof value === "number";
}

export function isNumeric(value) {
  if (isNumber(value)) return true;
  if (/^0x[0-9a-f]+$/i.test(value)) return true;
  return /^[-+]?(?:\d+(?:\.\d*)?|\.\d+)(e[-+]?\d+)?$/.test(value);
}

export function isObject(value) {
  return typeof value === "object";
}

export function isString(value) {
  return typeof value === "string";
}

export function isUndefined(value) {
  return typeof value === "undefined";
}

export function clone(source) {
  return JSON.parse(JSON.stringify(source));
}

export function clamp(value, min = 0, max = 1) {
  return Math.min(Math.max(value, min), max);
}

export const uid = (() => {
  let counter = 0;
  return (prefix = "") => prefix + String(++counter);
})();
