<template>
  <li :class="[entry.pending ? 'pending' : '', 'entry', 'repairs']">
    <div>
      <header class="entry-header repairs" @click="toggle">
        <div class="flex">
          <div class="flex-item">
            <Indicator :pass="entry.content.vacuum_spark_pass" />
          </div>
          <div class="flex-item">
            <h4>{{ entry.content.repair_number }}</h4>
          </div>
          <div class="flex-item">
            <h4>
              Panel: <span>{{ entry.content.panel }}</span>
            </h4>
          </div>
          <div
            class="flex-item"
            :class="[entry.content.void ? 'voided' : 'collapse']"
          >
            <span
              v-if="entry.content.void"
              v-text="entry.content.void ? 'V' : ''"
              class="void"
            />
          </div>
          <div class="flex-item">
            <Initials :initials="entry.content.tester" />
          </div>
        </div>
      </header>
      <div class="entry-content" v-if="isExpanded">
        <div
          v-if="entry.content.void"
          :class="[entry.content.void ? 'void' : '']"
        >
          <span>Voided</span>
        </div>
        <table>
          <thead>
            <tr>
              <th>Damage</th>
              <th>Test</th>
              <th>Seam</th>
              <th>Location</th>
              <th>Repair Type</th>
              <th>Repair Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div><span v-html="damage" /></div>
              </td>
              <td>
                <div>{{ entry.content.vacuum_spark }}</div>
              </td>
              <td>
                <div>{{ entry.content.seam }}</div>
              </td>
              <td>
                <div>{{ entry.content.location }}</div>
              </td>
              <td>
                <div>{{ entry.content.repair_type }}</div>
              </td>
              <td>
                <div><span v-html="formatDate" /></div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr
              v-if="
                this.entry.content.test_date && this.entry.content.test_time
              "
            >
              <td colspan="4">
                <TestDateTime
                  :date="this.entry.content.test_date"
                  :time="this.entry.content.test_time"
                />
              </td>
            </tr>
            <tr>
              <td colspan="4"><b>Posted:</b> <span v-html="date" /></td>
            </tr>
            <tr>
              <td colspan="4"><b>Notes:</b> {{ entry.content.notes }}</td>
            </tr>
          </tfoot>
        </table>
        <div v-if="!entry.content.void" class="actions">
          <Button @click="voidEntry($event)">
            Void Entry
          </Button>
          <Button @click="editEntry($event)" class="edit">
            Edit Entry
          </Button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import dayjs from "dayjs";
import { DOCUMENTATION } from "@/constants";
import Initials from "@/components/Initials";
import TestDateTime from "@/components/TestDateTime";

// const today = dayjs();

export default {
  components: {
    Initials,
    TestDateTime
  },
  emits: ["voidEntry", "editEntry"],
  props: {
    type: {
      type: String,
      required: true,
      validator: value => Object.keys(DOCUMENTATION).includes(value)
    },
    entry: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    }
  },

  data() {
    return {
      isExpanded: this.index === 0
    };
  },

  computed: {
    classes() {
      return [
        "entry",
        `entry:${this.type}`,
        this.isExpanded && "entry:expanded",
        this.entry.pass === false && "entry:fail"
      ].filter(Boolean);
    },
    date() {
      const date = dayjs(this.entry.content.date);
      return date.format("MM/DD/YY[&nbsp;&nbsp;]h:mm a");
    },
    formatDate() {
      return (
        this.entry.content.repair_date.split("-")[1] +
        "/" +
        this.entry.content.repair_date.split("-")[2] +
        "/" +
        this.entry.content.repair_date.split("-")[0]
      );
    },
    damage() {
      const damageType = {
        BO: "Burn Out",
        CS: "Cross Seam",
        DS: "Destructive Sample",
        SD: "Shipping Damage",
        IS: "Install Damage",
        DBO: "Damage by Others",
        other: "Other"
      };
      if (this.entry.content.damage === "other") {
        return this.entry.content.damage_other;
      } else {
        return damageType[this.entry.content.damage];
      }
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    voidEntry() {
      if (!window.confirm("Are you sure you want to void this entry?")) return;
      setTimeout(() => this.$emit("voidEntry"), 200);
    },
    editEntry() {
      this.$emit("editEntry");
    }
  }
};
</script>
