<template>
  <div>
    <h4 class="c-gray-7 mb-2">{{ today }}</h4>
    <p>
      Submitting this form will close the layer and you will no longer have
      access to add entries or make any further changes. Please make sure you do
      not need access to this layer before you proceed. <br />
      This action is final, and can not be undone.
    </p>

    <p>
      I have reviewed the work performed under the Contract and have found the
      work to be complete and conforming to the plans and specifications for the
      Project as of the Date of Issuance listed above.
    </p>

    <form
      class="form form:complete mt-5"
      method="post"
      @submit.prevent="onSubmit"
    >
      <fieldset class="mb-4">
        <Checkbox
          label="Yes, I want to complete this Layer"
          v-model="complete"
        />
      </fieldset>

      <Button type="submit" :disabled="disabled">
        <span>Complete Layer</span>
      </Button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import { COMPLETE } from "@/constants";

export default {
  data() {
    return {
      complete: false
    };
  },

  computed: {
    ...mapState(["user"]),
    today() {
      return dayjs().format("MMMM D, YYYY");
    },
    disabled() {
      return !this.complete;
    }
  },

  methods: {
    onSubmit() {
      this.$store.dispatch("entry", {
        type: COMPLETE,
        complete: this.complete
      });
    }
  }
};
</script>

<style lang="postcss">
.form\:complete {
  & fieldset {
    display: grid;
    grid-gap: var(--form-group-gap);
  }
}
</style>
