<template>
  <div class="field field:time">
    <label v-if="label">{{ label }}</label>
    <fieldset ref="fieldset">
      <input
        ref="hour"
        type="text"
        inputmode="numeric"
        maxlength="2"
        placeholder="00"
        :value="hour"
        @input="input($event, 'hour')"
        @focus="focus"
        @blur="blur"
        @contextmenu.prevent
      />
      <input
        ref="minute"
        type="text"
        inputmode="numeric"
        maxlength="2"
        placeholder="00"
        :value="minute"
        @input="input($event, 'minute')"
        @focus="focus"
        @blur="blur(), (minute = String(minute).padStart(2, '0'))"
        @contextmenu.prevent
      />
      <button type="button" @click="(pm = !pm), update()" tabindex="-1">
        {{ pm ? "pm" : "am" }}
      </button>
    </fieldset>
  </div>
</template>

<script>
import { clamp } from "@/utils";

export default {
  emits: ["update:modelValue"],

  props: {
    date: Date,
    label: String,
    isEdit: Boolean,
    modelValue: String
  },

  data() {
    if (this.isEdit) {
      /* eslint-disable */
      return (this.date = {
        hour:
          this.modelValue.split(":")[0] > 12
            ? this.modelValue.split(":")[0] - 12
            : this.modelValue.split(":")[0],
        minute: this.modelValue.split(":")[1],
        pm:
          this.modelValue.split(":")[0] >= 12 &&
          this.modelValue.split(":")[0] !== 24
      });
      /* eslint-enable */
    } else {
      const date = this.date || new Date();
      const hour = date.getHours();
      return this.date
        ? {
            hour: hour % 12 || 12,
            minute: String(date.getMinutes()).padStart(2, "0"),
            pm: hour >= 12 && hour !== 24
          }
        : {
            hour: "",
            minute: "",
            pm: hour >= 12 && hour !== 24
          };
    }
  },

  created() {
    this.date && this.update();
  },

  methods: {
    focus(event) {
      this.$refs.fieldset.classList.add("focus");
      event.target.select();
    },

    blur() {
      this.$refs.fieldset.classList.remove("focus");
    },

    input(event, key) {
      const input = event.target;
      const value = (input.value = input.value.replace(/\D/g, ""));
      this[key] = key === "hour" ? clamp(value, 1, 12) : clamp(value, 0, 59);

      if (key === "hour" && value.length === input.maxLength) {
        this.$refs.minute.select();
      }

      this.update();
    },

    update() {
      let hour = Number(this.hour);
      if (this.hour === 12) hour = 0;
      if (this.pm) hour += 12;
      const hh = String(hour).padStart(2, "0");
      const mm = String(this.minute).padStart(2, "0");
      this.$emit("update:modelValue", `${hh}:${mm}`);
    }
  }
};
</script>

<style>
.field\:time {
  max-width: 175px;

  & fieldset {
    position: relative;
    display: flex;
    border: solid var(--input-border-width) var(--input-border-color);
    border-radius: var(--border-radius-sm);
    background-color: var(--input-bg);
    transition: var(--input-transition);
    overflow: hidden;
  }
  & fieldset.focus {
    border-color: var(--input-border-focus);
    background-color: var(--input-bg-focus);
    box-shadow: var(--shadow-md);
  }
  & fieldset.focus:after {
    color: var(--input-color);
  }

  & fieldset:after {
    content: ":";
    position: absolute;
    left: 33%;
    top: 50%;
    font-weight: 600;
    color: var(--color-gray-5);
    transform: translateY(-50%);
    pointer-events: none;
  }
  & fieldset input,
  & fieldset input:focus {
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }
  & fieldset input:nth-child(1) {
    text-align: right;
    padding-left: 0.25rem;
    padding-right: 0.75rem;
  }
  & fieldset input:nth-child(2) {
    text-align: left;
    padding-left: 0.75rem;
    padding-right: 0.25rem;
  }

  & button {
    flex: 1 0 30%;
    font-weight: 600;
    text-align: center;
    border-left: solid 1px var(--input-border-color);
    background-color: var(--input-bg);
  }
}
</style>
