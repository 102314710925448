<template>
  <div :class="['field', 'field:input', prefix ? 'input-group prefix' : '']">
    <!-- <div class="field field:input"> -->
    <label v-if="label">{{ label }}</label>
    <!-- TODO: do we need a pattern="[0-9.]*" attribute? -->
    <span v-if="prefix" class="input-group-addon" v-text="prefix"></span>
    <input
      :class="[prefix ? 'prefixed' : '']"
      :type="type"
      :inputmode="mode"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    label: String,
    prefix: String,
    modelValue: [String, Number],
    modelModifiers: { default: () => ({}) }
  },
  computed: {
    type() {
      return this.modelModifiers.number ? "number" : "text";
    },
    mode() {
      return this.modelModifiers.number ? "decimal" : "text";
    }
  }
};
</script>

<style>
.prefix input {
  padding-left: 3.5em;
}
.prefix .input-group-addon {
  position: absolute;
  color: var(--color-gray-6);
  padding: var(--input-padding);
  font-size: var(--input-font-size);
  font-family: inherit;
  font-weight: 600;
  line-height: var(--input-line-height);
  background-color: var(--color-gray-3);
  border: solid var(--input-border-width) var(--input-border-color);
  border-radius: var(--input-border-radius);
  transition: var(--input-transition);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  -webkit-font-smoothing: antialiased;
}
.input-group-addon.prefix {
  border-radius: 4px 0px 0px 4px;
  border-right: 0;
}
.input-group {
  position: relative;
}
</style>
