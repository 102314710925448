<template>
  <div class="field field:range">
    <label v-if="label">{{ label }}</label>
    <div>
      <!-- TODO: these aren't always nubmers! See Panel Seaming -->
      <input
        type="text"
        class="range"
        :value="modelValue[0]"
        @input="input($event, 0)"
        :placeholder="labelStart"
      />
      <input
        type="text"
        class="range"
        :value="modelValue[1]"
        @input="input($event, 1)"
        :placeholder="labelEnd"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    label: String,
    labelStart: {
      type: String,
      default: "Start"
    },
    labelEnd: {
      type: String,
      default: "End"
    },
    modelValue: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    values: []
  }),
  methods: {
    input(event, index) {
      // this.values[index] = Number(event.target.value) || null;
      this.values[index] = event.target.value;
      const array = this.values.every(n => n === null) ? [] : this.values;
      this.$emit("update:modelValue", array);
    }
  }
};
</script>

<style>
.field\:range {
  & input:focus {
    position: relative;
    z-index: 2;
  }
  & input.range {
    width: 50%;
  }
  & input.range:first-child {
    width: calc(50% + var(--input-border-width));
    margin-right: calc(var(--input-border-width) * -1);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & input.range:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
