<template>
  <div class="initials">
    <span v-if="initials">{{ icon }}</span>
    <span v-else>?</span>
  </div>
</template>

<script>
export default {
  props: {
    initials: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      icon: ""
    };
  },
  created() {
    this.$store.dispatch("getUserInitals", this.initials).then(initials => {
      this.icon = initials;
    });
  }
};
</script>

<style>
.initials {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: var(--color-gray-1);
  background-color: var(--color-gray-7);
  border-radius: var(--border-radius-xs);
  line-height: 0;
}
</style>
