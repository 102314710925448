<template>
  <div class="avatar">
    <!-- <span v-if="user">{{ initials }}</span> -->
    <span v-if="user">{{ this.user.initials }}</span>
    <span v-else>?</span>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    initials() {
      if (!this.user) return;
      return (
        this.user.initials ||
        this.user.name
          .split(" ")
          .map(s => s[0])
          .join("")
      );
    }
  }
};
</script>

<style>
.avatar {
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  color: #333;
  background-color: #ccc;
  text-transform: uppercase;
}
</style>
