<template>
  <div class="field field:switch">
    <fieldset class="input">
      <div>
        <input
          id="switch-air_pressure"
          type="radio"
          name="avsProxy"
          :checked="modelValue === 'air_pressure'"
          @change="$emit('update:modelValue', 'air_pressure')"
        />
        <label for="switch-air_pressure">Air Pressure</label>
      </div>
      <div>
        <input
          id="switch-vacuum"
          type="radio"
          name="avsProxy"
          :checked="modelValue === 'vacuum'"
          @change="$emit('update:modelValue', 'vacuum')"
        />
        <label for="switch-vacuum">Vacuum</label>
      </div>
      <div>
        <input
          id="switch-spark"
          type="radio"
          name="avsProxy"
          :checked="modelValue === 'spark'"
          @change="$emit('update:modelValue', 'spark')"
        />
        <label for="switch-spark">Spark</label>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: String
  }
};
</script>

<style>
.avs.field\:switch {
  & fieldset {
    display: flex;
    align-self: center;
    height: var(--hack-input-height);
    padding: 4px;
  }
  & fieldset > div {
    width: 33%;
  }
  & fieldset input {
    position: absolute;
    opacity: 0;
  }
  & fieldset label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    color: var(--color-gray-6);
  }
  & fieldset input:checked + label {
    color: #fff;
    background-color: var(--color-green-3);
  }
}
</style>
