<template>
  <svg
    :class="['icon', 'icon-' + name]"
    :color="color"
    :width="width"
    :height="height || width"
  >
    <use :xlink:href="`#icon-${name}`"></use>
  </svg>
</template>

<script>
const icons = [
  "arrow-left",
  "check",
  "close", // times light
  "fail", // times solid
  "plus",
  "lock",
  "play",
  "layers"
];

export default {
  props: {
    name: {
      type: String,
      required: true,
      validator: value => icons.includes(value)
    },
    color: {
      type: String,
      default: "currentColor"
    },
    width: {
      type: [Number, String],
      default: "1em"
    },
    height: {
      type: [Number, String]
    }
    // :style="size && `font-size: ${size}`"
    // size: {
    //   type: String
    // }
  }
};
</script>

<style>
.icon {
  display: inline-flex;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  fill: currentColor;
  pointer-events: none;
}
</style>
