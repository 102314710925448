<template>
  <form class="form form:seaming" method="post" @submit.prevent="submit">
    <div class="form-group">
      <Employee label="Tester" role="tester" v-model="tester" />
      <Employee label="Welder" role="tester" v-model="seamer" />
    </div>

    <div class="form-group">
      <Input class="welds-machine" label="Machine" v-model.number="machine" />
      <Switch class="welds-welder" v-model="welder" />
    </div>
    <div
      class="form-group welds-welder-subfields"
      v-if="welder === 'extrusion'"
    >
      <Input label="Barrel Temp" v-model.number="extrusion_barrel" />
      <Input label="Preheat Temp" v-model.number="extrusion_preheat" />
    </div>

    <div class="form-group welds-welder-subfields" v-if="welder === 'wedge'">
      <Input label="Temp" v-model.number="wedge_temp" />
      <Input label="Speed" v-model.number="wedge_speed" />
    </div>

    <div class="form-group mb-2">
      <Input
        label="Temperature"
        class="temperature"
        v-model.number="temperature"
      />
      <Range class="seaming-station" label="Station" v-model="station" />
      <Time label="Start Time" v-model="time" :date="new Date()" />
    </div>

    <div class="form-group mb-2">
      <Input label="Seam ID" v-model="seam" />
      <Input label="Seam Location" v-model="location" />
      <Input label="Seam Length" v-model.number="length" />
    </div>

    <div class="form-group">
      <Date class="placement-date" label="Date" v-model="test_date" />
      <Time label="Time" v-model="test_time" :date="new Date()" />
    </div>

    <Text placeholder="Comments" v-model="notes" class="mb-2" />

    <Button type="submit" :disabled="disabled">
      Save Entry
    </Button>
  </form>
</template>

<script>
import { required, requiredIf } from "@vuelidate/validators";
import { SEAMING } from "@/constants";
import { clone } from "@/utils";

export default {
  data() {
    return {
      tester: this.$store.state.user.id,
      seamer: null,
      machine: null,
      welder: "extrusion",
      extrusion_barrel: null,
      extrusion_preheat: null,
      wedge_temp: null,
      wedge_speed: null,
      station: [],
      temperature: null,
      time: null,
      seam: null,
      location: null,
      length: null,
      test_date: null,
      test_time: null,
      notes: null
    };
  },

  validations() {
    return {
      tester: {
        required
      },
      seamer: {
        required
      },
      machine: {
        required
      },
      welder: {
        required
      },
      extrusion_barrel: {
        requiredIf: requiredIf(() => this.welder === "extrusion")
      },
      extrusion_preheat: {
        requiredIf: requiredIf(() => this.welder === "extrusion")
      },
      wedge_temp: {
        requiredIf: requiredIf(() => this.welder === "wedge")
      },
      wedge_speed: {
        requiredIf: requiredIf(() => this.welder === "wedge")
      },
      station: {
        required
      },
      temperature: {
        required
      },
      time: {
        required
      },
      seam: {
        required
      },
      location: {
        required
      },
      length: {
        required
      }
    };
  },

  // computed and updated are the same between all forms.
  // is there a better way to make this more DRY?
  computed: {
    disabled() {
      // return !this.$v.$dirty || this.$v.$errors.length;
      return false;
    }
  },
  updated() {
    this.$v.$touch();
  },

  methods: {
    submit() {
      const data = clone(this.$data);
      if (this.welder === "wedge") {
        delete data.extrusion_barrel;
        delete data.extrusion_preheat;
      } else {
        delete data.wedge_temp;
        delete data.wedge_speed;
      }
      data.type = SEAMING;
      this.$store.dispatch("entry", data);
    }
  }
};
</script>

<style lang="postcss">
.form\:seaming {
  & .seaming-station,
  & .seaming-machine {
    width: calc(50% - (var(--form-group-gap) / 2));
  }
  & .welds-welder-subfields .field {
    flex-basis: 120px;
  }
  & .temperature {
    flex-basis: 120px;
  }
}
</style>
