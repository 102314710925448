<template>
  <li :class="[entry.pending ? 'pending' : '', 'entry', 'destructive']">
    <div>
      <header class="entry-header destructive" @click="toggle">
        <div class="flex">
          <div class="flex-item"><Indicator :pass="entry.content.pass" /></div>
          <div class="flex-item">
            <h4>
              Sample: <span>{{ entry.content.sample }}</span>
            </h4>
          </div>
          <div class="flex-item">
            <h4>
              Seam: <span>{{ entry.content.seam }}</span>
            </h4>
          </div>
          <!-- <div class="flex-item"><span v-html="date" /></div> -->
          <div
            class="flex-item"
            :class="[entry.content.void ? 'voided' : 'collapse']"
          >
            <span
              v-if="entry.content.void"
              v-text="entry.content.void ? 'V' : ''"
              class="void"
            />
          </div>
          <div class="flex-item">
            <Initials :initials="entry.content.tester" />
          </div>
        </div>
      </header>
      <div class="entry-content" v-if="isExpanded">
        <div
          v-if="entry.content.void"
          :class="[entry.content.void ? 'void' : '']"
        >
          <span>Voided</span>
        </div>
        <table>
          <thead>
            <tr>
              <th>Machine</th>
              <th>Location</th>
              <th>Peel Tests</th>
              <th>Sheer Tests</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>{{ entry.content.machine }}</div>
              </td>
              <td>
                <div>{{ entry.content.location }}</div>
              </td>
              <td class="list">
                <ul>
                  <li
                    v-for="(pair, index) in entry.content.peel"
                    :key="'dpeel' + index"
                    :class="entry.content.welder === 'wedge' ? 'dual' : ''"
                  >
                    <div v-for="(item, index) in pair" :key="'ditem' + index">
                      <span
                        >{{ item }}
                        <span
                          v-if="index === 0 && entry.content.welder === 'wedge'"
                        >
                          /
                        </span></span
                      >
                    </div>
                  </li>
                </ul>
              </td>
              <td class="list">
                <ul>
                  <li
                    v-for="(item, index) in entry.content.shear"
                    :key="'dshear' + index"
                  >
                    <div>{{ item }}</div>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr
              v-if="
                this.entry.content.test_date && this.entry.content.test_time
              "
            >
              <td colspan="4">
                <TestDateTime
                  :date="this.entry.content.test_date"
                  :time="this.entry.content.test_time"
                />
              </td>
            </tr>
            <tr>
              <td colspan="4"><b>Posted:</b> <span v-html="date" /></td>
            </tr>
            <tr>
              <td colspan="4"><b>Notes:</b> {{ entry.content.notes }}</td>
            </tr>
          </tfoot>
        </table>
        <div v-if="!entry.content.void" class="actions">
          <Button @click="voidEntry($event)">
            Void Entry
          </Button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import dayjs from "dayjs";
import { DOCUMENTATION } from "@/constants";
import Initials from "@/components/Initials";
import TestDateTime from "@/components/TestDateTime";

// const today = dayjs();

export default {
  components: {
    Initials,
    TestDateTime
  },
  emits: ["voidEntry"],
  props: {
    type: {
      type: String,
      required: true,
      validator: value => Object.keys(DOCUMENTATION).includes(value)
    },
    entry: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      isExpanded: this.index === 0
    };
  },
  computed: {
    classes() {
      return [
        "entry",
        `entry:${this.type}`,
        this.isExpanded && "entry:expanded",
        this.entry.pass === false && "entry:fail"
      ].filter(Boolean);
    },
    date() {
      const date = dayjs(this.entry.content.date);
      return date.format("MM/DD/YY[&nbsp;&nbsp;]h:mm a");
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    voidEntry() {
      if (!window.confirm("Are you sure you want to void this entry?")) return;
      setTimeout(() => this.$emit("voidEntry"), 200);
    }
  }
};
</script>
