<template>
  <Authenticate v-if="!isAuthenticated" />
  <LoginForm
    v-else
    ref="childComponent"
    @onSubmit="handleSubmit"
    @onSignOut="onSignOut"
    @onCancel="onCancel"
  />
</template>

<script lang="ts">
// import { ref } from "vue";
import { mapState, mapGetters } from "vuex";
import router from "@/router";
import Authenticate from "@/views/Authenticate.vue";
import LoginForm from "@/components/LoginForm";
import _ from "lodash";

export default {
  name: "Login",
  components: {
    Authenticate,
    LoginForm
  },
  data: () => ({
    // isInvalid: false
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      users: state => state.users
    }),
    ...mapGetters({
      isLoading: "isLoading",
      isAuthenticated: "isAuthenticated"
    })
  },
  methods: {
    onSignOut() {
      this.$store.dispatch("signOutUser");
    },
    onCancel() {
      router.push({ path: "/projects/" });
    },
    handleInput(event) {
      if (event.target.value.length > 5) {
        event.preventDefault();
        // event.target.closest("form").submit();
        this.handleSubmit(event);
      }
    },
    handleSubmit() {
      // handleSubmit(event) {
      var vm = this;
      this.$store.dispatch("login", event.target.value).then(
        function() {
          router.push({ path: "/projects/" });
        },
        function() {
          // vm.invalid = true;
          vm.$refs.childComponent.resetForm();
        }
      );
    }
  },
  created: function() {
    if (this.isAuthenticated && !_.isEmpty(this.users)) {
      this.$store.dispatch("getUsers");
    }
  }
};
</script>

<style lang="postcss" scoped>
.login {
  /* display: flex;
  place-items: center; */
  width: 100vw;
  height: 100vh;
  background: var(--color-gray-2);
}

/* .frame {
  background: white;
  padding: 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  margin: auto;
}

.minimal {
  color: var(--color-green-2);
  text-decoration: none;
  font-family: var(--font-heading);
  font-weight: bold;
  font-size: 18px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.label {
  color: var(--color-gray-6);
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.input {
  display: flex;
  place-items: center;
  text-align: center;
  width: 70px;
  height: 70px;
  margin: 8px;
  font-size: 24px;

  &.active {
    border-color: var(--color-green-1);
  }
} */
</style>
