<template>
  <teleport to="body">
    <transition>
      <div v-if="open" class="sheet">
        <div>
          <div class="sheet-header" ref="header">
            <button type="button" @click="close">
              <Icon name="close" width="1.75em" />
            </button>
            <h3>
              <slot name="heading"></slot>
            </h3>
          </div>
          <div class="sheet-content" @scroll="scroll">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],

  props: {
    open: Boolean
  },

  mounted() {
    document.addEventListener("keyup", this.keyup);
  },

  unmounted() {
    document.removeEventListener("keyup", this.keyup);
  },

  updated() {
    document.body.style.overflow = this.open ? "hidden" : "initial";
  },

  methods: {
    scroll(event) {
      const content = event.target;
      this.$refs.header.classList.toggle("shadow", content.scrollTop > 1);
    },

    keyup(event) {
      event.key === "Escape" && this.open && this.$emit("close");
    },

    close() {
      if (
        !window.confirm(
          "Closing will reset the form. Are you sure you want to exit?"
        )
      )
        return;
      this.$store.commit("clearEntry");
      setTimeout(() => this.$emit("close"), 200);
    }
  }
};
</script>

<style lang="postcss">
.sheet {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 55, 72, 0.6);
  transition: background-color 300ms 200ms;

  &.v-leave-to,
  &.v-enter-from {
    background-color: rgba(0, 0, 0, 0);
  }

  &.v-leave-to > div,
  &.v-enter-from > div {
    transform: translateY(100%);
  }

  & > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 1.75rem);
    border-top-left-radius: var(--border-radius-lg);
    border-top-right-radius: var(--border-radius-lg);
    background-color: #fff;
    transition: transform 500ms var(--ease-in-out-quart);
    will-change: transform;
  }
}

.sheet-header {
  display: flex;
  align-items: center;
  padding: 2.1875rem;
  color: var(--color-gray-8);
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow-md);
    opacity: 0;
    transition: opacity 0.5s;
  }
  &.shadow:after {
    opacity: 1;
  }
  & h3 {
    margin: 0 0 0 1em;
    color: currentColor;
  }
  & h3 strong {
    margin-left: 0.25rem;
    color: var(--color-gray-5);
  }
}

.sheet-content {
  padding-top: var(--space-4);
  padding-bottom: var(--space-5);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;

  & form {
    max-width: 565px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
