<template>
  <div class="field field:text">
    <label v-if="label">{{ label }}</label>
    <div ref="wrap">
      <textarea
        :rows="rows || 1"
        :value="modelValue"
        @input="input"
        :placeholder="$attrs.placeholder"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],

  props: {
    rows: [Number, String],
    label: String,
    modelValue: String
  },

  mounted() {
    this.resize();
  },

  methods: {
    resize() {
      this.$refs.wrap.dataset.value = this.modelValue || "";
    },

    input(event) {
      this.$emit("update:modelValue", event.target.value);
      this.$nextTick(() => this.resize());
    }
  }
};
</script>

<style>
.field\:text {
  /* https://css-tricks.com/auto-growing-inputs-textareas/#other-ideas */
  & > div {
    display: grid;
  }
  & > div:after {
    content: attr(data-value) " ";
    padding: var(--input-padding);
    border: var(--input-border-width) solid transparent;
    font-size: var(--input-font-size);
    font-family: inherit;
    font-weight: 600;
    line-height: var(--input-line-height);
    visibility: hidden;
    white-space: pre-wrap;
  }
  & > div:after,
  & > div textarea {
    grid-area: 1 / 1;
  }

  & textarea {
    overflow: auto;
    resize: none;
  }
}
</style>
