<template>
  <Loading v-if="isLoading" />
  <div class="app" v-else>
    <NetworkStatus />
    <RouterView />
  </div>
  <Icons />
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  data: () => ({
    isLoading: true,
    isLoginOpen: false
  }),
  components: {},
  computed: {
    ...mapState({
      token: state => state.token,
      user: state => state.user
    }),
    ...mapGetters({
      isAuthenticated: "isAuthenticated"
    })
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      window._mfq = window._mfq || [];
      var mf = document.createElement("script");
      mf.type = "text/javascript";
      mf.defer = true;
      mf.src =
        "//cdn.mouseflow.com/projects/0a654e00-712a-4e3d-8c7b-256f7db52833.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    }
  },
  async created() {
    this.isLoading = false;
  },
  beforeUpdate() {
    // TODO: is this necessary?
    // if (!this.user) this.isLoginOpen = false;
  }
};
</script>

<style></style>
