<template>
  <Loading v-if="isLoading" />
  <div :class="['layer', `layer:${view}`, `${view}`]">
    <Header
      :project="{
        client: site.name,
        location: layer.name,
        type: layer.type,
        color: layer.color
      }"
    />

    <div class="pad pt-0">
      <div class="layer-nav mb-3">
        <select @change="navigate">
          <option
            v-for="(name, key) in documentation"
            :value="key"
            :key="key"
            :selected="view === key"
          >
            {{ name }}
          </option>
        </select>
        <Button :disabled="!form" @click="isFormOpen = true">
          <Icon name="plus" width="1.667em" />
        </Button>
      </div>

      <div
        class="my-4 flex items-start justify-between"
        v-if="view !== 'signoff' || view !== 'complete'"
      >
        <div>
          <h4 class="mb-1 c-gray-7">{{ layer.material }}</h4>
        </div>

        <table
          class="layer-thresholds"
          v-if="view === 'welds' || view === 'destructive'"
        >
          <tr>
            <th>&nbsp;</th>
            <th class="text-xs">Wedge</th>
            <th class="text-xs">Extrusion</th>
          </tr>
          <tr>
            <th class="text-xs">Peel</th>
            <td>{{ thresholds.wedge_peel }}</td>
            <td>{{ thresholds.extrusion_peel }}</td>
          </tr>
          <tr>
            <th class="text-xs">Shear</th>
            <td>{{ thresholds.wedge_shear }}</td>
            <td>{{ thresholds.extrusion_shear }}</td>
          </tr>
        </table>
      </div>

      <div v-if="view !== 'signoff' || view !== 'complete'">
        <ol v-if="view === 'welds'">
          <EntryWelds
            v-for="entry in weldsOrdered"
            :key="`${entry.content.date}-${entry.saved}`"
            :type="view"
            :entry="entry"
            @voidEntry="voidEntry($event, entry)"
          />
        </ol>
        <ol v-if="view === 'non-destructive'">
          <EntryNondestructive
            v-for="entry in nonDestructiveOrdered"
            :key="`${entry.content.date}-${entry.saved}`"
            :type="view"
            :entry="entry"
            @voidEntry="voidEntry($event, entry)"
            @editEntry="editEntry($event, entry)"
          />
        </ol>
        <ol v-if="view === 'destructive'">
          <EntryDestructive
            v-for="entry in destructiveOrdered"
            :key="`${entry.content.date}-${entry.saved}`"
            :type="view"
            :entry="entry"
            @voidEntry="voidEntry($event, entry)"
          />
        </ol>
        <ol v-if="view === 'placement'">
          <EntryPlacement
            v-for="entry in placementOrdered"
            :key="`${entry.content.date}-${entry.saved}`"
            :type="view"
            :entry="entry"
            @voidEntry="voidEntry($event, entry)"
          />
        </ol>
        <ol v-if="view === 'seaming'">
          <EntrySeaming
            v-for="entry in seamingOrdered"
            :key="`${entry.content.date}-${entry.saved}`"
            :type="view"
            :entry="entry"
            @voidEntry="voidEntry($event, entry)"
          />
        </ol>
        <ol v-if="view === 'repairs'">
          <EntryRepairs
            v-for="entry in repairsOrdered"
            :key="`${entry.content.date}-${entry.saved}`"
            :type="view"
            :entry="entry"
            @voidEntry="voidEntry($event, entry)"
            @editEntry="editEntry($event, entry)"
          />
        </ol>
        <ol v-if="view === 'subgradesignoff'">
          <EntryDailySignOff
            v-for="entry in layer.entries.filter(
              entry => entry.type === 'subgradesignoff'
            )"
            :key="`${entry.content.date}-${entry.saved}`"
            :type="view"
            :entry="entry"
            @voidEntry="voidEntry($event, entry)"
          />
        </ol>
      </div>

      <SignOff class="mt-4" v-if="view === 'signoff'" />
      <Complete class="mt-4" v-if="view === 'complete'" />
    </div>

    <Sheet v-if="form" :open="isFormOpen" @close="isFormOpen = false">
      <template v-slot:heading>
        {{ layer.name }} <strong>{{ documentation[view] }}</strong>
      </template>
      <component :is="form" @submit="isFormOpen = false" />
    </Sheet>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";

import Loading from "@/components/Loading";
import Header from "@/components/Header";
import Sheet from "@/components/Sheet";
import EntryNondestructive from "@/components/EntryNondestructive";
import EntryDestructive from "@/components/EntryDestructive";
import EntryPlacement from "@/components/EntryPlacement";
import EntryRepairs from "@/components/EntryRepairs";
import EntrySeaming from "@/components/EntrySeaming";
import EntryWelds from "@/components/EntryWelds";
import EntryDailySignOff from "@/components/EntryDailySignOff";
// documentation forms
import WeldsForm from "@/components/1-WeldsForm";
import NonDestructiveForm from "@/components/2-NonDestructiveForm";
import DestructiveForm from "@/components/3-DestructiveForm";
import PlacementForm from "@/components/4-PlacementForm";
import SeamingForm from "@/components/5-SeamingForm";
import RepairsForm from "@/components/6-RepairsForm";
import DailySignOff from "@/components/8-DailySignOff";
import SignOff from "@/components/7-SignOff";
import Complete from "@/components/9-CompleteLayer";

import {
  DOCUMENTATION,
  // MATERIALS,
  WELDS,
  NON_DESTRUCTIVE,
  DESTRUCTIVE,
  PLACEMENT,
  SEAMING,
  REPAIRS,
  SUBGRADESIGNOFF
  // SIGNOFF
} from "@/constants";

export default {
  components: {
    Loading,
    Header,
    Sheet,
    EntryNondestructive,
    EntryDestructive,
    EntryPlacement,
    EntryRepairs,
    EntrySeaming,
    EntryWelds,
    EntryDailySignOff,
    DailySignOff,
    SignOff,
    Complete
  },
  props: ["view"],
  data: () => ({
    isLoading: true,
    isFormOpen: false
  }),
  computed: {
    ...mapGetters(["layer", "entries", "materials"]),
    thresholds() {
      // console.log("thresholds", this.$store.state.thresholds);
      // console.log("thresholds not empty", !_.isEmpty(this.$store.state.thresholds));
      if (
        !_.isEmpty(this.$store.state.thresholds) &&
        this.$store.state.thresholds[this.layer.material]
      ) {
        // console.log("thresholds store", this.$store.state.thresholds[this.layer.material]);
        // const threshold = _.cloneDeep(this.$store.state.thresholds);
        // console.log("materials store", _.cloneDeep(this.$store.state.materials));
        // console.log("this.layer.material", this.layer.material);
        // const material = _.cloneDeep(this.$store.state.materials);
        // console.log("material", material[this.layer.material]);
        // console.log("thresholds material", threshold[this.layer.material]);
        return this.$store.state.thresholds[this.layer.material];
        // return threshold[this.layer.material];
      } else {
        // console.log("materials store", this.$store.state.materials[this.layer.material]);
        // console.log("materials store", _.cloneDeep(this.$store.state.materials));
        // console.log("this.layer.material", this.layer.material);
        // const material = _.cloneDeep(this.$store.state.materials);
        // return material[this.layer.material];
        return this.$store.state.materials[this.layer.material];
      }
    },
    ...mapState({
      user: state => state.user,
      project: state => state.project,
      site: state => state.site,
      layer: state => state.layer
    }),
    documentation() {
      return DOCUMENTATION;
    },
    form() {
      switch (this.view) {
        case WELDS:
          return WeldsForm;
        case NON_DESTRUCTIVE:
          return NonDestructiveForm;
        case DESTRUCTIVE:
          return DestructiveForm;
        case PLACEMENT:
          return PlacementForm;
        case SEAMING:
          return SeamingForm;
        case REPAIRS:
          return RepairsForm;
        case SUBGRADESIGNOFF:
          return DailySignOff;
        default:
          return null;
      }
    },
    weldsOrdered() {
      let welds = this.layer.entries.filter(entry => entry.type === "welds");
      let weldsDeduped = Object.values(
        _.cloneDeep(welds).reduce(
          (acc, cur) => Object.assign(acc, { [cur.content.date]: cur }),
          {}
        )
      );
      // console.log("weldsDeduped", weldsDeduped);
      return _.orderBy(weldsDeduped, "content.date", "desc");
    },
    placementOrdered() {
      let placements = this.layer.entries.filter(
        entry => entry.type === "placement"
      );
      let placementDeduped = Object.values(
        _.cloneDeep(placements).reduce(
          (acc, cur) => Object.assign(acc, { [cur.content.date]: cur }),
          {}
        )
      );
      // console.log("placementDeduped", placementDeduped);
      return _.orderBy(placementDeduped, "content.number", "asc");
    },
    seamingOrdered() {
      let seams = this.layer.entries.filter(entry => entry.type === "seaming");
      let seamingDeduped = Object.values(
        _.cloneDeep(seams).reduce(
          (acc, cur) => Object.assign(acc, { [cur.content.date]: cur }),
          {}
        )
      );
      // console.log("seamingDeduped", seamingDeduped);
      return _.orderBy(seamingDeduped, "content.seam", "asc");
    },
    nonDestructiveOrdered() {
      let tests = this.layer.entries.filter(
        entry => entry.type === "non-destructive"
      );
      let nonDestructiveDeduped = Object.values(
        _.cloneDeep(tests).reduce(
          (acc, cur) => Object.assign(acc, { [cur.content.date]: cur }),
          {}
        )
      );
      // console.log("nonDestructiveDeduped", nonDestructiveDeduped);
      return _.orderBy(nonDestructiveDeduped, "content.seam", "asc");
    },
    destructiveOrdered() {
      let tests = this.layer.entries.filter(
        entry => entry.type === "destructive"
      );
      let destructiveDeduped = Object.values(
        _.cloneDeep(tests).reduce(
          (acc, cur) => Object.assign(acc, { [cur.content.date]: cur }),
          {}
        )
      );
      // console.log("destructiveDeduped", destructiveDeduped);
      return _.orderBy(destructiveDeduped, "content.sample", "asc");
    },
    repairsOrdered() {
      let tests = this.layer.entries.filter(entry => entry.type === "repairs");
      let repairsDeduped = Object.values(
        _.cloneDeep(tests).reduce(
          (acc, cur) => Object.assign(acc, { [cur.content.date]: cur }),
          {}
        )
      );
      // console.log("repairsDeduped", repairsDeduped);
      return _.orderBy(repairsDeduped, "content.repair_number", "asc");
    }
  },
  methods: {
    navigate(event) {
      // TODO: remove the project ID from route paths?
      this.$router.push({
        name: "layer",
        params: {
          project: this.$route.params.project,
          layer: this.$route.params.layer,
          view: event.target.value
        }
      });
    },
    voidEntry(event, entry) {
      const voided = _.cloneDeep(entry);
      voided.content.void = true;
      this.$store.dispatch("voidEntry", voided);
    },
    editEntry(event, entry) {
      this.$store.commit("setEntry", entry);
      this.isFormOpen = true;
    }
  },
  created() {
    this.$store.dispatch("entries", this.view);
    this.$store.dispatch("getLayer", this.$route.params.layer);
  },
  mounted() {
    // let sites = _.flattenDeep(this.$store.state.projects.projects[0].sites);
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.isLoading = false;
    });
  },
  watch: {
    view() {
      this.$store.dispatch("entries", this.view);
    }
  }
};
</script>

<style lang="postcss">
.layer {
  height: 100%;
  background-color: var(--color-gray-2);
}
.layer\:signoff,
.layer\:complete {
  background-color: #fff;
}

.layer .entry {
  display: none;
}
.layer.welds .entry.welds,
.layer.non-destructive .entry.non-destructive,
.layer.destructive .entry.destructive,
.layer.placement .entry.placement,
.layer.seaming .entry.seaming,
.layer.repairs .entry.repairs,
.layer.subgradesignoff .entry.subgradesignoff {
  display: list-item;
}

.entry.pending {
  background-color: var(--color-gray-4);
}

.collapse,
.voided {
  min-width: 2rem;
  height: 2rem;
  padding: 0 !important;
  font-size: 1.25rem;
  font-weight: bold;
}

.void {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: var(--color-gray-1);
  background-color: var(--color-yellow);
  border-radius: var(--border-radius-xs);
  line-height: 0;
}

.layer-nav {
  display: flex;
  height: 5rem;

  & select,
  & select:focus {
    font-size: 1.625rem;
    font-weight: 700;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;
    color: var(--color-gray-8);
    background-color: #fff;
    box-shadow: var(--shadow-md);
  }
  & button {
    flex: 1 0 130px;
    margin-left: 2rem;
    padding: 0;
    background-color: var(--color-green-2);
  }
}

.layer-thresholds {
  border-spacing: 0;
  & th {
    line-height: 1;
  }
  & td {
    min-width: 150px;
    font-size: var(--text-md);
    font-weight: 700;
    color: var(--color-green-1);
    padding: 0.4em;
    text-align: center;
    background-color: #fff;
  }
  & tr:first-child th {
    padding-bottom: 0.625rem;
    text-align: center;
  }
  & tr:not(:first-child) th {
    padding-right: 0.75rem;
    text-align: right;
  }
  & tr:nth-child(2) td:nth-child(2) {
    border-top-left-radius: var(--border-radius-sm);
    border-right: 1px solid var(--color-gray-3);
    border-bottom: 1px solid var(--color-gray-3);
    box-shadow: var(--shadow-xs);
  }
  & tr:nth-child(2) td:nth-child(3) {
    border-top-right-radius: var(--border-radius-sm);
    border-bottom: 1px solid var(--color-gray-3);
    box-shadow: var(--shadow-xs);
  }
  & tr:nth-child(3) td:nth-child(2) {
    border-bottom-left-radius: var(--border-radius-sm);
    border-right: 1px solid var(--color-gray-3);
    box-shadow: var(--shadow-xs);
  }
  & tr:nth-child(3) td:nth-child(3) {
    border-bottom-right-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-xs);
  }
}
.entry {
  width: 100%;
  padding: 0.3125rem;
  border-radius: var(--border-radius-sm);
  background-color: #fff;
  box-shadow: var(--shadow-sm);
}

.entry + .entry {
  margin-top: 0.625rem;
}

.entry\:expanded .entry-header {
  background: var(--color-gray-1);
}

.entry\:fail h4 {
  color: var(--color-red-dark);
}

.entry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  border-radius: var(--border-radius-sm);

  & i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background-color: white;
    border: solid 1px var(--color-gray-5);
    border-radius: var(--border-radius-xs);
    line-height: 0;
    margin-right: 0.5rem;
  }

  & .flex {
    justify-content: space-between;
    width: 100%;
    align-items: center;

    & .flex-item {
      padding: 0 1rem;
    }

    & .flex-item:first-of-type {
      flex-basis: 5%;
      padding: 0;
    }

    & .flex-item:nth-of-type(2) {
      flex-basis: 20%;
      text-align: left;
    }

    & .flex-item:nth-of-type(3) {
      flex-basis: 40%;
    }

    & .flex-item:nth-of-type(4) {
      flex-basis: 33%;
      text-align: right;
    }
    & .flex-item:last-of-type {
      flex-basis: 5%;
      padding: 0;
    }
  }

  & h4 {
    margin: 0;
    display: inline-flex;
    align-items: center;
    & span {
      font-weight: normal;
      margin-left: 0.25rem;
    }
  }
}

.entry-header.welds {
  & .flex {
    & .flex-item:nth-of-type(2) {
      flex-basis: calc(22% - 2rem);
      text-align: left;
    }

    & .flex-item:nth-of-type(3) {
      flex-basis: calc(25% - 2rem);
    }

    & .flex-item:nth-of-type(4) {
      flex-basis: calc(38% - 2rem);
    }

    & .flex-item:nth-of-type(5) {
      flex-basis: calc(5% - 2rem);
    }
  }
}

.entry-header.non-destructive {
  & .flex {
    & .flex-item:nth-of-type(2) {
      flex-basis: calc(30% - 2rem);
      text-align: left;
    }
    & .flex-item:nth-of-type(3) {
      flex-basis: calc(30% - 2rem);
    }
    & .flex-item:nth-of-type(4) {
      flex-basis: calc(25% - 2rem);
    }
    & .flex-item:nth-of-type(5) {
      flex-basis: 5%;
    }
  }
}

.entry-header.destructive {
  & .flex {
    & .flex-item:nth-of-type(2) {
      flex-basis: calc(40% - 2rem);
      text-align: left;
    }
    & .flex-item:nth-of-type(3) {
      flex-basis: calc(40% - 2rem);
    }
    & .flex-item:nth-of-type(4) {
      flex-basis: 5%;
    }
  }
}

.entry-header.placement {
  & .flex {
    & .flex-item:first-of-type {
      flex-basis: calc(45% - 2rem);
    }

    & .flex-item:nth-of-type(2) {
      flex-basis: calc(45% - 2rem);
      text-align: right;
    }

    & .flex-item:nth-of-type(3) {
      flex-basis: calc(5% - 2rem);
    }
  }
  & h4 {
    text-transform: uppercase;

    & span {
      margin-left: 0.25rem;
      font-weight: bold;
    }
  }
}

.entry-header.seaming {
  & .flex {
    justify-content: space-between;
    & .flex-item:first-of-type {
      flex-basis: calc(45% - 2rem);
    }

    & .flex-item:nth-of-type(2) {
      flex-basis: calc(45% - 2rem);
      text-align: left;
    }

    & .flex-item:nth-of-type(3) {
      flex-basis: calc(5% - 2rem);
    }
  }
}

.entry-header.repairs {
  & .flex {
    justify-content: space-between;
    & .flex-item:nth-of-type(2) {
      flex-basis: calc(45% - 2rem);
      text-align: left;
    }

    & .flex-item:nth-of-type(3) {
      flex-basis: calc(40% - 2rem);
      text-align: left;
    }
    & .flex-item:nth-of-type(4) {
      flex-basis: calc(5% - 2rem);
    }
  }
}

.entry-header.subgradesignoff {
  & .flex {
    & .flex-item:first-of-type {
      flex-basis: calc(30% - 2rem);
    }

    & .flex-item:nth-of-type(2) {
      flex-basis: calc(25% - 2rem);
      text-align: left;
    }

    & .flex-item:nth-of-type(3) {
      flex-basis: calc(35% - 2rem);
      text-align: right;
    }
    & .flex-item:nth-of-type(4) {
      flex-basis: calc(5% - 2rem);
    }
  }
}

.placement .entry-content table thead th:last-child,
.placement .entry-content table tbody td:last-child,
.seaming .entry-content table thead th:last-child,
.seaming .entry-content table tbody td:last-child,
.repairs .entry-content table thead th:last-child,
.repairs .entry-content table tbody td:last-child {
  text-align: right;
}

.entry-content {
  padding: 0.5rem;
  & .void {
    width: 100%;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
  }
  & table {
    width: 100%;
    margin-bottom: 2rem;
    border-collapse: collapse;
    & thead {
      background-color: var(--color-gray-7);
      & th {
        color: var(--color-gray-1);
        text-transform: uppercase;
        padding: 0.25rem;
      }
    }
    & tbody {
      & td:not(.list) {
        /* text-align: center; */
      }
      & td {
        padding: 0.25rem;
        font-weight: bold;
        vertical-align: top;
        & li {
          display: inline-block;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
        & li.dual {
          & div:first-of-type {
            padding-right: 0;
          }
          & div:last-of-type {
            padding-left: 0.25rem;
          }
        }
        & div {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0.5rem;
          background-color: var(--color-gray-1);
          border-radius: var(--border-radius-xs);
        }
      }
    }
    & tfoot {
      & td {
        padding: 1rem 0;
      }
    }
  }
  & .actions {
    display: flex;
    align-content: flex-end;
    flex-direction: row-reverse;
    padding: 1rem 0;
    & .button {
      padding: 0.5rem 2rem;
      background-color: var(--color-yellow);
    }
    & .button.edit {
      margin-right: 2rem;
      background-color: var(--color-gray-7);
    }
  }
}
</style>
