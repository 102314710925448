<template>
  <Loading v-if="isLoading" />
  <header class="header">
    <div class="w-100 flex items-center justify-between">
      <div class="logo">
        <router-link :to="{ name: 'info' }"
          ><img src="/img/logo.png" width="77" height="50"
        /></router-link>
        <span>IEC Field Docs</span>
      </div>
      <button type="button" @click="$refs.modalName.openModal()">
        <Avatar :user="user" />
      </button>
    </div>
    <div class="w-100 flex items-center justify-between">
      <h1>Projects</h1>
      <button
        type="button"
        :class="[connected ? '' : 'disabled', 'projectsPull']"
        @click="pullProjects()"
      >
        Refresh Projects
      </button>
    </div>
    <logInModal ref="modalName" />
  </header>
  <div class="projects">
    <ul v-if="!hasProjects">
      <li class="project-card">No Projects Assigned</li>
    </ul>
    <ul v-else>
      <li v-for="project in projects" :key="project.id" class="project-card">
        <router-link :to="{ name: 'project', params: { project: project.id } }">
          <div>
            <ol class="title">
              <li>{{ project.customer }}</li>
              <li>{{ project.name }}</li>
            </ol>
            <div class="details">
              <div class="layers">
                <Icon name="layers" />
                <span>{{ project.layerCount }} Layers</span>
              </div>
              <div>{{ project.address1 }}</div>
              <div>{{ project.address2 }}</div>
            </div>
            <div class="details">
              <p>{{ project.notes }}</p>
            </div>
          </div>

          <div class="play">
            <Icon name="play" />
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import logInModal from "@/components/LoginModal";
import Loading from "@/components/Loading";

export default {
  components: { logInModal, Loading },
  data: () => ({
    selection: null,
    isLoading: true
  }),
  computed: {
    ...mapGetters(["hasProjects", "connected"]),
    ...mapState({
      user: state => state.user,
      projects: state => state.projects.projects
    })
  },
  methods: {
    toggle(value) {
      if (this.selection === value) this.selection = null;
    },
    submit() {
      this.$store.dispatch("project", this.selection);
    },
    pullProjects() {
      if (!this.connected) {
        window.confirm(
          "This functionality is currently unavailable. It requires an internet connection."
        );
      } else {
        this.isLoading = true;
        this.$store.dispatch("resetProject");
        this.$store.dispatch("getProjects", this.user.id).then(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
      }
    }
  },
  beforeMount() {
    // shut off automatic projects pull - adding manual button for pull
    if (!this.hasProjects) {
      this.$store.dispatch("getProjects", this.user.id);
    }
    // this.$store.dispatch("getMaterials");
    // this.$store.commit("resetProject");
  },
  mounted() {
    // let sites = _.flattenDeep(this.$store.state.projects.projects[0].sites);
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      if (this.connected) this.$store.dispatch("getMaterials");
      this.isLoading = false;
    });
  },
  created() {
    // shut off automatic projects pull - adding manual button for pull
    // this.$store.dispatch("resetProject");
  }
};
</script>

<style scoped lang="postcss">
.header {
  padding: 24px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--color-gray-8);
  color: white;
}
h1 {
  font-size: var(--text-xl);
  font-weight: md;
  margin: 0;
  margin-top: 2em;
  color: white;
  align-self: flex-start;
}
button.projectsPull {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: white;
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-md);
  color: var(--color-gray-8);
  line-height: 1;
  text-decoration: none;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.25s;
}
button.projectsPull.disabled {
  background-color: var(--color-gray-6);
  box-shadow: none;
}

.logo {
  display: flex;
  align-items: center;
  font-size: var(--text-lg);
  font-weight: 700;
  & img {
    margin-right: 1rem;
  }
}
.projects {
  /* background: var(--color-gray-2); */
  height: 100%;
  padding: 0 24px;

  & > ul {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;
  }
}

.project-card {
  background: white;
  padding: 32px;
  border-radius: 4px;
  transform: translateY(-32px);

  & > a {
    display: flex;
  }

  & .title {
    display: flex;
    font-size: var(--text-lg);
    gap: 8px;
    font-weight: bold;

    & > li {
      color: var(--color-gray-5);
    }
    & > li:first-of-type {
      color: var(--color-gray-8);
    }
  }

  & .details {
    display: flex;
    align-items: center;
    color: var(--color-gray-5);
    gap: 16px;
  }

  & .layers {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & .play {
    background: var(--color-yellow);
    --size: 64px;
    width: var(--size);
    min-width: 64px;
    height: var(--size);
    border-radius: calc(var(--size) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-xl);
    margin-left: auto;
    padding-left: 0.175em;
    color: var(--color-gray-7);
  }
}
</style>
