<template>
  <div>
    <h4 class="c-gray-7 mb-2">{{ today }}</h4>
    <p>
      I, the undersigned Manufacturer's Representative, hereby certify that I am
      a duly authorized representative of the manufacturer, empowered by the
      manufacturer to inspect and approve their equipment and authorized to make
      recommendations required to assure that the equipment furnished by the
      manufacturer is complete and operational, except as may be otherwise
      indicted herein. I further certify that all information contained herein
      is true and accurate.
    </p>
    <h3>Description of Work Completed</h3>
    <div class="description" v-html="layer.description"></div>
    <p v-if="layer.type === 'cover'">
      General maintenance will be needed during the lifespan of your gas
      collection cover. Plants may begin to grow in the dirt that will collect
      on the cover. It is best to remove these plants so the flow of rainwater
      to the discharge pumps remains unimpeded. Any debris that begins to
      collect on the surface of the cover should be removed to prevent the
      discharge pumps from becoming clogged, and to prevent any damage to the
      geomembrane. Discharge pumps should not be exposed to icy conditions. If
      the temperature of the water in the basin is going to fall below freezing,
      remove and drain discharge pumps until the basin warms up. Additional sand
      tubes will be left on site after our installation crew departs. Do not
      throw them away. They are there for you to use to deal with any areas of
      the cover that are not draining properly. They can be placed on the cover
      to create an area for the rainwater to flow from a pooling spot to the
      nearest collection trench. Sand tubes should not be placed in an area
      where they could potentially block gas flow. If there is any question
      about the placement of the sand tube please contact our main office for
      assistance.
    </p>
    <p>
      I have reviewed the work performed under the Contract and have found the
      work to be complete and conforming to the plans and specifications for the
      Project as of the Date of Issuance listed above.
    </p>

    <form
      class="form form:signoff mt-5"
      method="post"
      @submit.prevent="onSubmit"
    >
      <fieldset class="mb-4" v-show="!bypass">
        <div class="form-group">
          <Input label="Customer Representative" v-model="customer.name" />
          <Input label="Title" v-model="customer.title" />
        </div>
        <Text placeholder="Comments" v-model="customer.comments" />
        <Signature
          :data="customer.signature"
          @signed="onSigned('customer', $event)"
        />
      </fieldset>

      <fieldset>
        <div class="form-group">
          <Input label="IEC Representative" v-model="employee.name" />
          <Input label="Title" v-model="employee.title" />
        </div>
        <Text
          v-if="!bypass"
          :placeholder="bypass ? 'Reason for bypass' : 'Comments'"
          v-model="employee.comments"
        />
        <Signature
          :data="employee.signature"
          @signed="onSigned('employee', $event)"
        />
      </fieldset>

      <Checkbox label="Bypass customer sign-off" v-model="bypass" />
      <fieldset v-if="bypass">
        <Text
          :placeholder="bypass ? 'Reason for bypass' : 'Comments'"
          v-model="employee.comments"
        />
      </fieldset>

      <Button type="submit" :disabled="disabled">
        <span>Submit Sign-off</span>
      </Button>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import dayjs from "dayjs";
import { SIGNOFF } from "@/constants";

export default {
  data() {
    const user = this.$store.state.user;

    const customer = {
      name: null,
      title: null,
      company: null, // TODO: this should come from the project state
      comments: null,
      signature: null
    };

    const employee = {
      name: user.name,
      title: user.role,
      // company: 'Environmental & Industrial Concepts, Inc',
      comments: null,
      signature: null
    };

    return {
      customer,
      employee,
      bypass: false
    };
  },

  computed: {
    ...mapState(["user"]),
    // TODO: does this need to be a getter?
    ...mapGetters(["layer"]),
    today() {
      return dayjs().format("MMMM D, YYYY");
    },
    disabled() {
      return (
        !this.employee.signature ||
        (!this.employee.comments && this.bypass) ||
        (!this.customer.signature && !this.bypass)
      );
    }
  },

  methods: {
    onSigned(who, image) {
      this[who].signature = image;
    },

    onSubmit() {
      this.$store.dispatch("entry", {
        type: SIGNOFF,
        bypassed: this.bypass,
        customer: this.bypass ? {} : { ...this.customer },
        employee: { ...this.employee }
      });
    }
  }
};
</script>

<style lang="postcss">
.form\:signoff {
  & fieldset {
    display: grid;
    grid-gap: var(--form-group-gap);
  }
}
.description {
  margin-bottom: 1.5em;
}
</style>
