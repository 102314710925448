<template>
  <li :class="[entry.pending ? 'pending' : '', 'entry', 'subgradesignoff']">
    <div>
      <header class="entry-header subgradesignoff" @click="toggle">
        <div class="flex">
          <div class="flex-item">
            <h4>{{ entry.content.employee.name }}</h4>
          </div>
          <div class="flex-item">
            <span v-if="entry.content.bypassed"><b>No Signoff</b></span>
          </div>
          <div class="flex-item"><span v-html="date" /></div>
          <div
            class="flex-item"
            :class="[entry.content.void ? 'voided' : 'collapse']"
          >
            <span
              v-if="entry.content.void"
              v-text="entry.content.void ? 'V' : ''"
              class="void"
            />
          </div>
          <div class="flex-item">
            <Initials :initials="entry.employee_id" />
          </div>
        </div>
      </header>
      <div class="entry-content" v-if="isExpanded">
        <div
          v-if="entry.content.void"
          :class="[entry.content.void ? 'void' : '']"
        >
          <span>Voided</span>
        </div>
        <table>
          <thead>
            <tr v-if="entry.content.customer.name">
              <th>Customer</th>
              <th>Title</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="entry.content.customer.name">
              <td>
                <div>{{ entry.content.customer.name }}</div>
              </td>
              <td>
                <div>{{ entry.content.customer.title }}</div>
              </td>
              <td>
                <div>{{ entry.content.customer.comments }}</div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3">
                <b>Employee Comments:</b> {{ entry.content.employee.comments }}
              </td>
            </tr>
          </tfoot>
        </table>
        <!-- <div v-if="!entry.content.void && !entry.pending" class="actions"> -->
        <div v-if="!entry.content.void" class="actions">
          <Button @click="voidEntry($event)">
            Void Entry
          </Button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import dayjs from "dayjs";
import { DOCUMENTATION } from "@/constants";
import Initials from "@/components/Initials";

// const today = dayjs();

export default {
  components: {
    Initials
  },
  emits: ["voidEntry"],
  props: {
    type: {
      type: String,
      required: true,
      validator: value => Object.keys(DOCUMENTATION).includes(value)
    },
    entry: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    }
  },

  data() {
    return {
      isExpanded: this.index === 0
    };
  },

  computed: {
    classes() {
      return [
        "entry",
        `entry:${this.type}`,
        this.isExpanded && "entry:expanded",
        this.entry.pass === false && "entry:fail"
      ].filter(Boolean);
    },
    date() {
      const date = dayjs(this.entry.content.date);
      return date.format("MM/DD/YY[&nbsp;&nbsp;]h:mm a");
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    voidEntry() {
      if (!window.confirm("Are you sure you want to void this entry?")) return;
      setTimeout(() => this.$emit("voidEntry"), 200);
    }
  }
};
</script>
