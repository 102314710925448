<template>
  <div class="field field:employee">
    <label v-if="label">{{ label }}</label>
    <select :class="{ 'has-value': hasValue }" @change="update">
      <option value="">
        Please Select
      </option>
      <option
        v-for="user in project_users"
        :key="user.id"
        :value="user.id"
        :selected="user.id === modelValue"
      >
        {{ user.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const roles = ["supervisor", "tester", "seamer"];

export default {
  emits: ["update:modelValue"],
  props: {
    role: {
      type: String,
      validator: value => roles.includes(value)
    },
    label: String,
    modelValue: Number
  },
  computed: {
    ...mapGetters(["project_users"]),
    hasValue() {
      const value = this.modelValue;
      return value && this.project_users.find(user => user.id === value);
    }
  },
  methods: {
    update(event) {
      const value = Number(event.target.value) || null;
      this.$emit("update:modelValue", value);
    }
  }
};
</script>
