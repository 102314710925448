<template>
  <li :class="[entry.pending ? 'pending' : '', 'entry', 'non-destructive']">
    <div>
      <header class="entry-header non-destructive" @click="toggle">
        <div class="flex">
          <div class="flex-item">
            <Indicator
              v-if="entry.content.hasOwnProperty('test_passed')"
              :pass="entry.content.test_passed"
            />
          </div>
          <div class="flex-item">
            <h4>
              Seam: <span>{{ entry.content.seam }}</span>
            </h4>
          </div>
          <div class="flex-item">
            <h4>
              Location: <span>{{ entry.content.location }}</span>
            </h4>
          </div>
          <div class="flex-item"><span v-html="testType"></span></div>
          <div
            class="flex-item"
            :class="[entry.content.void ? 'voided' : 'collapse']"
          >
            <span
              v-if="entry.content.void"
              v-text="entry.content.void ? 'V' : ''"
              class="void"
            />
          </div>
          <div class="flex-item">
            <Initials :initials="entry.content.tester" />
          </div>
        </div>
      </header>
      <div class="entry-content" v-if="isExpanded">
        <div
          v-if="entry.content.void"
          :class="[entry.content.void ? 'void' : '']"
        >
          <span>Voided</span>
        </div>
        <table>
          <thead>
            <tr>
              <th>Station</th>
              <th
                v-if="
                  entry.content.air_pressure_vacuum_spark === 'air_pressure'
                "
              >
                PSI
              </th>
              <th
                v-if="
                  entry.content.air_pressure_vacuum_spark === 'air_pressure'
                "
              >
                Start Time
              </th>
              <th
                v-if="
                  entry.content.air_pressure_vacuum_spark === 'air_pressure'
                "
              >
                End Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                :style="
                  entry.content.air_pressure_vacuum_spark !== 'air_pressure'
                    ? 'width: 50%;'
                    : ''
                "
                class="list"
              >
                <ul>
                  <li
                    v-for="(item, index) in entry.content.station"
                    :key="'ndstationpeel' + index"
                  >
                    <div>{{ item }}</div>
                  </li>
                </ul>
              </td>
              <td
                v-if="
                  entry.content.air_pressure_vacuum_spark === 'air_pressure'
                "
                class="list"
              >
                <ul>
                  <li
                    v-for="(item, index) in entry.content.psi"
                    :key="'ndpsi' + index"
                  >
                    <div>{{ item }}</div>
                  </li>
                </ul>
              </td>
              <td
                v-if="
                  entry.content.air_pressure_vacuum_spark === 'air_pressure'
                "
              >
                <div>{{ formatTime[0] }}</div>
              </td>
              <td
                v-if="
                  entry.content.air_pressure_vacuum_spark === 'air_pressure'
                "
              >
                <div>{{ formatTime[1] }}</div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr
              v-if="
                this.entry.content.test_date && this.entry.content.test_time
              "
            >
              <td colspan="4">
                <TestDateTime
                  :date="this.entry.content.test_date"
                  :time="this.entry.content.test_time"
                />
              </td>
            </tr>
            <tr>
              <td colspan="4"><b>Posted:</b> <span v-html="date" /></td>
            </tr>
            <tr>
              <td colspan="5"><b>Notes:</b> {{ entry.content.notes }}</td>
            </tr>
          </tfoot>
        </table>
        <div v-if="!entry.content.void" class="actions">
          <Button @click="voidEntry($event, entry)">
            Void Entry
          </Button>
          <Button @click="editEntry($event)" class="edit">
            Edit Entry
          </Button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import dayjs from "dayjs";
import { DOCUMENTATION } from "@/constants";
import Initials from "@/components/Initials";
import TestDateTime from "@/components/TestDateTime";
import _ from "lodash";

// const today = dayjs();

export default {
  components: {
    Initials,
    TestDateTime
  },
  emits: ["voidEntry", "editEntry"],
  props: {
    type: {
      type: String,
      required: true,
      validator: value => Object.keys(DOCUMENTATION).includes(value)
    },
    entry: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    }
  },

  data() {
    return {
      isExpanded: this.index === 0
    };
  },

  computed: {
    classes() {
      return [
        "entry",
        `entry:${this.type}`,
        this.isExpanded && "entry:expanded",
        this.entry.pass === false && "entry:fail"
      ].filter(Boolean);
    },
    date() {
      const date = dayjs(this.entry.content.date);
      return date.format("MM/DD/YY[&nbsp;&nbsp;]h:mm a");
    },
    formatTime() {
      let formattedTime = [];
      if (this.entry.content.air_pressure_vacuum_spark !== "air_pressure")
        return false;
      this.entry.content.time.forEach((item, i) => {
        if (_.isNull(this.entry.content.time[i])) return false;
        let formated = {
          hour:
            this.entry.content.time[i].split(":")[0] > 12
              ? this.entry.content.time[i].split(":")[0] - 12
              : this.entry.content.time[i].split(":")[0],
          minute: this.entry.content.time[i].split(":")[1],
          pm:
            this.entry.content.time[i].split(":")[0] >= 12 &&
            this.entry.content.time[i].split(":")[0] !== 24
              ? "pm"
              : "am"
        };
        formattedTime.push(
          formated.hour + ":" + formated.minute + " " + formated.pm
        );
      });
      return formattedTime;
    },
    testType() {
      switch (this.entry.content.air_pressure_vacuum_spark) {
        case "air_pressure":
          return "Air Pressure";
        case "vacuum":
          return "Vacuum";
        case "spark":
          return "Spark";
        default:
          return null;
      }
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    voidEntry() {
      if (!window.confirm("Are you sure you want to void this entry?")) return;
      setTimeout(() => this.$emit("voidEntry"), 200);
    },
    editEntry() {
      this.$emit("editEntry");
    }
  }
};
</script>
