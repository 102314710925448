import axios from "axios";
import db from "@/db";
import _ from "lodash";
import router from "@/router";
import { MATERIALS } from "@/constants";
import store from "../store";

// import httpService from "@/constants/http.service";

const { VUE_APP_API_URL: API_URL } = process.env;
axios.defaults.baseURL = `${API_URL}/api`;
axios.defaults.headers.common["Accept"] = "application/json";
export default {
  activate(code) {
    const config = {
      "Content-Type": "multipart/form-data"
    };
    const formData = new FormData();
    formData.append("code", code);
    return axios
      .post(`/activate`, formData, { headers: config })
      .then(response => {
        const token = _.get(response, "data.key");
        if (token) {
          return { success: true, token: token };
        } else {
          return { success: false, message: _.get(response, "data.error") };
        }
      })
      .catch(response => {
        return { success: false, message: _.get(response, "data.error") };
      });
  },
  getUsers() {
    const config = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    };
    return axios
      .get(`/employees`, { headers: config })
      .then(async users => {
        const em = (await db).transaction("users", "readwrite");
        await Promise.all([
          ...users.data.map(row => em.store.put({ ...row, saved: 1 })),
          em.done
        ]);
        return users.data;
      })
      .catch(function(e) {
        console.log("failed response", e.response);
        if (e.response.status === 403) {
          window.localStorage.clear();
          router.go({ path: "/", force: true });
        }
      });
  },
  getProjects(id) {
    const config = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    };
    return axios
      .get(`/sync?id=${id}`, { headers: config })
      .then(async projects => {
        const em = (await db).transaction("projects", "readwrite");
        await Promise.all([
          ...projects.data.projects.map(row =>
            em.store.put({ ...row, saved: 1 })
          ),
          em.done
        ]);
        return projects.data;
      })
      .catch(function(e) {
        console.log("projects axios failed response", e.response);
        if (e.response.status === 403) {
          window.localStorage.clear();
          router.go({ path: "/", force: true });
        }
      });
  },
  postEntries(entries) {
    const config = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    };
    return axios
      .post("/entries", JSON.stringify(entries), { headers: config })
      .then(async response => {
        const tx = (await db).transaction("entries", "readwrite");
        await Promise.all([
          ...entries.map(row => tx.store.put({ ...row, saved: 1 })),
          tx.done
        ]);
        return response.data;
      })
      .catch(function(e) {
        console.log("failed response", e.response);
        if (e.response.status === 403) {
          window.localStorage.clear();
          router.go({ path: "/", force: true });
        }
      });
  },
  getMaterials() {
    const config = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json"
    };
    return axios
      .get(`/materials`, { headers: config })
      .then(async materials => {
        // console.log("materials", materials);
        return materials.data;
      })
      .catch(function(e) {
        console.log("materials failed response", e);
        // console.log("Constants MATERIALS", MATERIALS);
        // console.log("has Materials in the store", store.getters.hasMaterials);
        if (store.getters.hasMaterials) {
          return store.getters.materials;
        } else {
          return MATERIALS;
        }
      });
  }
};
