<template>
  <div
    class="marker"
    :style="{ '--background': checked ? 'var(--color-gray-4)' : background }"
  >
    <div v-if="type && !checked">{{ markerIcon }}</div>
    <Icon v-else name="check" />
  </div>
</template>

<script>
// const colors = {
//   red: "#EF4444",
//   orange: "#F97316",
//   amber: "#F59E0B",
//   yellow: "#F0CC00",
//   lime: "#84CC16",
//   green: "#22C55E",
//   emerald: "#10B981",
//   teal: "#14B8A6",
//   cyan: "#06B6D4",
//   aqua: "#0EA5E9",
//   blue: "#3B82F6",
//   indigo: "#6366F1",
//   violet: "#8B5CF6",
//   purple: "#A855F7",
//   fuchsia: "#D946EF",
//   pink: "#EC4899",
//   rose: "#F43F5E"
// };
export default {
  props: {
    color: { type: String, default: "blue" },
    type: { type: String },
    checked: { type: Boolean, default: false }
  },
  computed: {
    background() {
      // return colors[this.color];
      return this.color;
    },
    markerIcon() {
      return this.type.charAt(0).toUpperCase();
    }
  }
};
</script>

<style lang="postcss" scoped>
.marker {
  --overlay: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(0, 0, 0, 0.15) 100%
  );
  --size: 64px;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: var(--text-xl);
  font-weight: bold;
  background: var(--background) var(--overlay);
  background-blend-mode: overlay;
  border-radius: 4px;
}
</style>
