<template>
  <div class="field field:checkbox">
    <input
      :id="id"
      type="checkbox"
      :checked="!!modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
import { uid } from "@/utils";

export default {
  emits: ["update:modelValue"],
  props: {
    label: String,
    modelValue: Boolean
  },
  computed: {
    id() {
      return uid("checkbox-");
    }
  }
};
</script>

<style>
.field\:checkbox {
  display: flex;
  align-items: center;
  align-self: center;

  & input + label {
    margin: 0 0 0 0.75rem;
  }
  & input {
    appearance: none;
    width: 3rem;
    height: 1.75rem;
    margin: 0;
    border-radius: calc(var(--input-border-radius) - 1px);
    background-color: var(--input-border-color);
    position: relative;
    transition: var(--input-transition);
  }
  & input:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: var(--border-radius-xs);
    background: #fff;
    transition: var(--input-transition);
  }
  & input:checked {
    background-color: var(--color-green-1);
  }
  & input:checked:after {
    transform: translateX(100%);
  }
}
</style>
