<template>
  <div :class="['indicator', `indicator:${pass ? 'pass' : 'fail'}`]">
    <Icon :name="pass ? 'check' : 'fail'" />
  </div>
</template>

<script>
export default {
  props: {
    pass: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style>
.indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: var(--border-radius-xs);
  line-height: 0;
}
.indicator\:pass {
  color: #fff;
  background-color: var(--color-green-1) !important;
}
.indicator\:fail {
  color: #fff;
  background-color: var(--color-red-light) !important;
}
</style>
