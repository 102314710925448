<template>
  <div class="login">
    <div v-if="user && Object.keys(user).length === 0" class="frame">
      <Avatar class="avatar" :user="user" />
      <h2>Sign in</h2>
      <form class="form" @submit="handleSubmit($event)">
        <label class="label" for="pin">Enter Pin</label>
        <input
          autofocus
          ref="input"
          class="visually-hidden"
          id="pin"
          type="number"
          :maxlength="5"
          v-model="pin"
          @input="handleInput($event)"
        />
        <div class="flex" @click="focusInput($event)">
          <div
            v-for="index of 6"
            class="input"
            :class="{ active: pin.length === index - 1 }"
            v-html="pin[index - 1]"
            :key="index"
          />
        </div>
      </form>
      <span class="invalid">{{ isInvalid ? "invalid code" : "&nbsp;" }}</span>
    </div>

    <div v-else class="frame">
      <Avatar class="avatar" :user="user" />
      <h2>Signed in as <span v-html="user.name"></span></h2>
      <button class="minimal" @click="onSignOut()">Sign Out</button>
      <button v-if="route === 'login'" class="minimal" @click="onCancel()">
        Cancel
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { mapState } from "vuex";
// import _ from "lodash";
export default {
  name: "Login",
  emits: ["onSignOut", "onCancel", "onSubmit"],
  setup() {
    const input = ref(null);
    const pin = ref("");
    const focusInput = () => input.value.focus();
    const isInvalid = false;
    return {
      pin,
      input,
      focusInput,
      isInvalid
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      users: state => state.users
    }),
    route() {
      return this.$route.name;
    }
  },
  methods: {
    onSignOut() {
      this.$emit("onSignOut");
    },
    onCancel() {
      this.$emit("onCancel");
    },
    resetForm() {
      //code to reset form
      this.isInvalid = true;
      this.pin = "";
      this.input.blur();
    },
    handleInput(event) {
      if (event.target.value.length > 5) {
        event.preventDefault();
        this.handleSubmit(event);
      }
    },
    handleSubmit(event) {
      event.preventDefault();
      this.$emit("onSubmit");
    }
  }
};
</script>

<style lang="postcss" scoped>
.login {
  display: flex;
  place-items: center;
}
.invalid {
  color: var(--color-red-light);
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}

.frame {
  background: white;
  padding: 32px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  margin: auto;
}

.minimal {
  color: var(--color-green-2);
  text-decoration: none;
  font-family: var(--font-heading);
  font-weight: bold;
  font-size: 18px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.label {
  color: var(--color-gray-6);
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.input {
  display: flex;
  place-items: center;
  text-align: center;
  width: 70px;
  height: 70px;
  margin: 8px;
  font-size: 24px;
  color: var(--color-gray-7);

  &.active {
    border-color: var(--color-green-1);
  }
}
.select {
  color: var(--color-gray-7);
}
</style>
