<template>
  <router-link :to="{ name: 'info' }">
    <div id="network-status" class="network-status"></div>
  </router-link>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "NetworkStatus",
  computed: {
    ...mapGetters(["connected"]),
    ...mapState({
      network: state => state.network
    })
  },
  async created() {
    window.addEventListener("load", () => {
      function handleNetworkChange() {
        let { onLine, connection } = window.navigator;
        let isReliable = ["3g", "4g"].includes(connection.effectiveType);
        if (onLine && isReliable) {
          document.body.classList.remove("offline");
        } else {
          document.body.classList.add("offline");
        }
      }
      window.addEventListener("online", handleNetworkChange);
      window.addEventListener("offline", handleNetworkChange);
    });
  }
};
</script>

<style>
#network-status::before,
#network-status::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

#network-status::before {
  border-width: 1.5rem;
  border-right-color: var(--color-green-1);
  border-top-color: var(--color-green-1);
}

#network-status::after {
  border-radius: 0.4rem;
  border-width: 1.35rem;
  border-right-color: var(--color-green-1);
  border-top-color: var(--color-green-1);
}
.offline {
  & #network-status::before {
    border-right-color: var(--color-red-light);
    border-top-color: var(--color-red-light);
  }
  & #network-status::after {
    border-right-color: var(--color-red-light);
    border-top-color: var(--color-red-light);
  }
}
</style>
