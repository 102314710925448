<template>
  <div class="field field:signature">
    <label>Signature</label>
    <div class="wrap">
      <canvas ref="canvas"></canvas>
    </div>
    <div class="btns" v-if="!data">
      <button type="button" @click="clear">clear</button>
      <button type="button" @click="accept" :disabled="isEmpty">accept</button>
    </div>
  </div>
</template>

<script>
import SignaturePad from "signature_pad";

export default {
  emits: ["signed"],

  props: {
    data: String
  },

  data: () => ({
    isEmpty: true
  }),

  mounted() {
    const ratio = window.devicePixelRatio;
    const canvas = this.$refs.canvas;
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: "transparent",
      onBegin: () => document.activeElement.blur(),
      onEnd: () => (this.isEmpty = false)
    });
  },

  unmounted() {
    this.signaturePad.off();
  },

  methods: {
    clear() {
      this.signaturePad.clear();
      this.isEmpty = true;
    },
    accept() {
      const image = this.signaturePad.toDataURL("image/svg+xml");
      this.signaturePad.off();
      this.$emit("signed", image);
    }
  }
};
</script>

<style>
.field\:signature {
  height: 280px;
  background-color: #fff;
  position: relative;
  border: solid 1px var(--color-gray-4);
  border-radius: var(--input-border-radius);

  & label {
    position: absolute;
    z-index: 1;
    left: 2.5em;
    bottom: 1.667em;
    margin: 0 !important;
    padding-left: 1em;
    padding-right: 1em;
    background-color: #fff;
    pointer-events: none;
  }

  & .wrap {
    height: 100%;
  }
  & .wrap:before {
    content: "";
    position: absolute;
    left: 24px;
    top: 24px;
    width: calc(100% - 52px);
    height: calc(100% - 60px);
    border: dashed 2px var(--input-border-color);
    border-radius: var(--input-border-radius);
  }
  & .wrap canvas {
    width: 100%;
    height: 100%;
    position: relative;
  }

  & .btns {
    position: absolute;
    z-index: 1;
    right: 2.5em;
    bottom: 0.7em;
    padding: 5px 0.75em;
    border-radius: var(--input-border-radius);
    line-height: 1;
    background-color: #fff;
  }
  /* TODO: make this a button variant? */
  & .btns button {
    font-size: var(--text-sm);
    font-weight: 600;
    padding: 1.25em 1em;
    border: 2px solid currentColor;
    border-radius: var(--border-radius-sm);
    color: var(--color-gray-5);
    transition: var(--input-transition);
  }
  & .btns button:first-child {
    margin-right: 0.8em;
  }
  & .btns button:last-child:not(:disabled) {
    color: #fff;
    border-color: var(--color-green-1);
    background-color: var(--color-green-1);
  }
}
</style>
