<template>
  <div :class="['field', 'field:tests', `${type}-tests`, `${welder}`]">
    <label>{{ label }}</label>
    <ul>
      <li v-for="(field, i) in tests" :key="i">
        <div v-if="type === 'peel' && welder === 'extrusion'">
          <input
            type="text"
            maxlength="3"
            :value="field"
            @blur="validator($event, 'peel')"
            @input="input($event, i)"
          />
          <Button class="remove" :disabled="disabled" @click="remove(i)">
            <Icon name="close" width="1.25rem" />
          </Button>
        </div>
        <div v-else-if="type === 'peel'">
          <input
            type="text"
            maxlength="3"
            :value="field[0]"
            @blur="validator($event, 'peel')"
            @input="input($event, i, 0)"
          />
          <input
            type="text"
            maxlength="3"
            :value="field[1]"
            @blur="validator($event, 'peel')"
            @input="input($event, i, 1)"
          />
          <Button class="remove" :disabled="disabled" @click="remove(i)">
            <Icon name="close" width="1.25rem" />
          </Button>
        </div>
        <div v-else>
          <input
            type="text"
            maxlength="3"
            :value="field"
            @blur="validator($event, 'shear')"
            @input="input($event, i)"
          />
          <Button class="remove" :disabled="disabled" @click="remove(i)">
            <Icon name="close" width="1.25rem" />
          </Button>
        </div>
      </li>
      <li class="actions">
        <Button class="add" :disabled="disabled" @click="add">
          <Icon name="plus" width="1.25rem" />
        </Button>
      </li>
    </ul>
  </div>
</template>

<script>
import { isEmpty } from "@/utils";
import { mapGetters } from "vuex";
// import { MATERIALS } from "@/constants";
const PEEL = "peel";
const SHEAR = "shear";

export default {
  emits: ["update:modelValue"],

  props: {
    type: {
      type: String,
      validator: value => [PEEL, SHEAR].includes(value)
    },
    welder: {
      type: String
    },
    modelValue: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    tests: []
  }),

  computed: {
    ...mapGetters(["materials"]),
    label() {
      const type = this.type;
      return `${type[0].toUpperCase()}${type.slice(1)} Tests`;
    },

    disabled() {
      const tests = this.tests;
      if (tests.length === 0) return false;

      return this.type === PEEL && this.welder === "wedge"
        ? tests.some(
            field => field.length < 2 || isEmpty(field[0]) || isEmpty(field[1])
          )
        : tests.some(field => field === null);
    }
  },

  methods: {
    add() {
      this.tests.push(this.type === PEEL ? [] : null);
      this.$nextTick(() =>
        this.$el.querySelector("li:nth-last-child(2) input").focus()
      );
    },

    remove(index) {
      this.tests.splice(index, 1);
      this.update();
    },

    input(event, index, field = 0) {
      let value = event.target.value;
      value = value === "" || isNaN(value) ? null : Number(event.target.value);

      if (this.type === PEEL) {
        this.tests[index][field] = value;
      } else {
        this.tests[index] = value;
      }

      this.update();
    },

    validator(event, type) {
      const material = this.$store.state.layer.material;
      const peelMin = this.$store.state.materials[material][
        `${this.welder}_peel`
      ];
      // console.log("peelMin", peelMin);
      const shearMin = this.$store.state.materials[material][
        `${this.welder}_shear`
      ];
      // console.log("shearMin", shearMin);

      if (this.welder === "wedge" && type === "peel") {
        if (event.target.value >= peelMin) {
          event.target.classList.value = "valid";
        } else {
          event.target.classList.value = "error";
        }
      } else if (type === "peel") {
        if (event.target.value >= peelMin) {
          event.target.closest("li").classList.value = "valid";
        } else {
          event.target.closest("li").classList.value = "error";
        }
      } else {
        if (event.target.value >= shearMin) {
          event.target.closest("li").classList.value = "valid";
        } else {
          event.target.closest("li").classList.value = "error";
        }
      }
    },

    update() {
      const tests = [...this.tests];
      const value =
        this.type === PEEL
          ? tests.filter(
              field =>
                field.length !== 0 && (field[0] !== null || field[1] !== null)
            )
          : tests.filter(field => field !== null);

      this.$emit("update:modelValue", value);
    }
  }
};
</script>

<style>
.field\:tests {
  & .error,
  & .error input,
  & input.error {
    border-color: var(--color-red-light) !important;
  }
  & .valid,
  & .valid input,
  & input.valid {
    border-color: var(--color-green-1) !important;
  }
  & ul {
    display: grid;
    grid-gap: 14px;
  }
  & li {
    position: relative;
  }
  & li > div {
    display: flex;
  }
  & li > div input {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    max-width: 5rem;
  }
  & li > div input:focus {
    position: relative;
    z-index: 2;
  }

  & li.actions {
    display: flex;
  }

  & button.add {
    min-height: var(--hack-input-height);
    height: 100%;
    padding: var(--input-padding);
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 1rem;
    color: var(--color-green-1);
    border: var(--input-border-width) solid var(--color-green-1);
    background-color: #fff;
    box-shadow: none;
    transition: none;
  }
  & button.add:disabled,
  & button.remove:disabled {
    color: #fff;
    border-color: var(--color-gray-4);
    background-color: var(--color-gray-4);
  }

  & button.remove {
    min-height: var(--hack-input-height);
    height: 100%;
    padding: var(--input-padding);
    padding-top: 0;
    padding-bottom: 0;
    color: var(--color-red-light);
    border: var(--input-border-width) solid var(--color-red-light);
    background-color: #fff;
    box-shadow: none;
    transition: none;
  }

  &.shear-tests ul {
    /* grid-template-columns: repeat(auto-fill, minmax(75px, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
  &.peel-tests ul {
    /* grid-template-columns: repeat(auto-fill, minmax(130px, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
  &.peel-tests.extrusion ul {
    /* grid-template-columns: repeat(auto-fill, minmax(75px, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
  &.peel-tests input:first-child {
    margin-right: calc(var(--input-border-width) * -1);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.peel-tests input:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
