import { openDB } from "idb";

const {
  VUE_APP_DB_NAME: DB_NAME,
  VUE_APP_DB_VERSION: DB_VERSION
} = process.env;

const db = openDB(DB_NAME, DB_VERSION, {
  upgrade(db) {
    db.createObjectStore("projects", {
      keyPath: "id"
    });

    db.createObjectStore("project", {
      keyPath: "id"
    });

    // db.createObjectStore("sites", {
    //   keyPath: "id"
    // });
    //
    // db.createObjectStore("site", {
    //   keyPath: "id"
    // });
    //
    // db.createObjectStore("layer", {
    //   keyPath: "id"
    // });
    //
    // db.createObjectStore("project_users", {
    //   keyPath: "id"
    // });

    db.createObjectStore("users", {
      keyPath: "pin"
    });

    db.createObjectStore("user", {
      keyPath: "id"
    });

    const entries = db.createObjectStore("entries", {
      keyPath: "date"
    });

    entries.createIndex("type", ["layer_id", "type"]);
    entries.createIndex("saved", "saved");

    db.createObjectStore("errors");
    db.createObjectStore("storage");
  }
});

db.storage = {
  async get(key) {
    return (await db).get("storage", key);
  },
  async set(key, value) {
    return (await db).put("storage", value, key);
  },
  async delete(key) {
    return (await db).delete("storage", key);
  },
  async clear() {
    return (await db).clear("storage");
  },
  async keys() {
    return (await db).getAllKeys("storage");
  }
};

export default db;
