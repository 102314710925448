<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal__backdrop" @click="closeModal()" />

      <div class="modal__dialog">
        <div class="modal__header">
          <slot name="header" />
          <button type="button" class="modal__close" @click="closeModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button>
        </div>

        <div class="modal__body">
          <LoginForm
            ref="childComponent"
            @onSubmit="handleSubmit"
            @onSignOut="onSignOut"
            @onCancel="closeModal"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from "vuex";
import LoginForm from "@/components/LoginForm";

export default {
  name: "logInModal",
  components: {
    LoginForm
  },
  data: () => ({
    show: false,
    pin: ""
  }),
  computed: mapState(["user"]),
  // mounted() {
  //   this.$refs.input.focus();
  // },
  methods: {
    ...mapActions(["logout"]),
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    onSignOut() {
      this.$store.dispatch("signOutUser");
    },
    handleInput(event) {
      if (event.target.value.length > 5) {
        event.preventDefault();
        this.handleSubmit(event);
      }
    },
    handleSubmit() {
      var vm = this;
      this.$store.dispatch("login", event.target.value).then(
        function() {
          if (vm.$route.name === "projects") {
            vm.$router.go();
          } else {
            vm.closeModal();
          }
        },
        function() {
          vm.$refs.childComponent.resetForm();
        }
      );
    }
  }
};
</script>

<style>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
.modal__backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.modal__dialog {
  background-color: white;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 992px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  z-index: 2;
}
.modal__close {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-gray-7);
}
.modal__header {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: flex-end;
}
.modal__body {
  padding: 0 0 2rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
