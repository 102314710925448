import { createRouter, createWebHistory } from "vue-router";
import { isEmpty } from "@/utils";
import store from "@/store";
// import Authenticate from "@/views/Authenticate.vue";
import Login from "@/views/Login.vue";
import Projects from "@/views/Projects.vue";
import Project from "@/views/Project.vue";
import Layer from "@/views/Layer.vue";
import Info from "@/views/Info.vue";

// TODO: update route path patterns?
// / = Home
// - Authentication if no token or not installed
// - Login if no user in localStorage
// - Projects if there is no project in IndexedDB (or localStorage)
// - Project if three is a project "checked out" ... or?
// /project = Project if there is a project "checked out"?
// /project/layer:123
// /project/layer:123/welds

const routes = [
  // {
  //   path: "/",
  //   name: "authenticate",
  //   component: Authenticate
  // },
  {
    path: "/",
    name: "login",
    component: Login
  },
  {
    path: "/info",
    name: "info",
    component: Info
  },
  {
    path: "/projects",
    name: "projects",
    component: Projects
    // component: () => import(/* webpackChunkName: "projects" */ "@/views/Projects")
  },
  {
    path: "/projects/:project",
    name: "project",
    component: Project
    // component: () => import(/* webpackChunkName: "project" */ "@/views/Project")
  },
  {
    path: "/projects/:project/:layer/:view?",
    name: "layer",
    component: Layer,
    // component: () => import(/* webpackChunkName: "project" */ "@/views/Layer"),
    props: route => ({ view: route.params.view || "welds" })
  },
  {
    path: "/:path(.*)",
    redirect: { name: "login" }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  linkActiveClass: "",
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (isEmpty(store.state.user) && to.name !== "login") {
    return next({ name: "login" });
  }

  // attempt to push unsaved entries when the layer view changes
  if (from.name === "layer") {
    store.dispatch("push");
  }

  // reset entries on every view change so there isn't
  // a flash of old data while new entries are being loaded
  store.commit("update", {
    name: "entries",
    data: []
  });

  next();
});

export default router;
