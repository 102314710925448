<template>
  <form
    class="form form:non-destructive"
    method="post"
    @submit.prevent="submit"
  >
    <div class="form-group">
      <Employee label="Tester" role="tester" v-model="tester" />
      <Employee label="Observer" role="tester" v-model="observerProxy" />
    </div>

    <AVSSwitch class="avs" v-model="avsProxy" />

    <div class="form-group mt-1">
      <Input
        class="non-destructive-seam"
        label="Seam Number"
        v-model="seamProxy"
      />
      <Range
        class="non-destructive-station"
        label="Station"
        v-model="stationProxy"
      />
    </div>

    <fieldset v-if="avsProxy === 'air_pressure'" class="my-2">
      <h4>Air Pressure</h4>

      <div class="form-group">
        <Range label="PSI" v-model="psiProxy" />
        <Time
          v-if="!isEdit"
          label="Start Time"
          v-model="startTimeProxy"
          :date="new Date()"
        />
        <Time
          v-else
          label="Start Time"
          v-model="startTimeProxy"
          :isEdit="isEdit"
        />
        <Time
          v-if="isEdit && endTimeProxy !== null"
          label="End Time"
          v-model="endTimeProxy"
          :date="new Date()"
        />
        <Time v-else label="End Time" v-model="endTimeProxy" />
      </div>
    </fieldset>

    <fieldset
      v-if="avsProxy === 'vacuum' || avsProxy === 'spark'"
      class="form-group non-destructive-checkboxes mb-2"
    >
      <Checkbox label="Test passed" v-model="test_passedProxy" />
    </fieldset>

    <div class="form-group">
      <Date class="placement-date" label="Date" v-model="test_dateProxy" />
      <Time label="Time" v-model="test_timeProxy" :date="new Date()" />
    </div>

    <Text placeholder="Location" v-model="locationProxy" />
    <Text placeholder="Comments" v-model="notesProxy" />

    <Button type="submit" :disabled="disabled">
      Save Entry
    </Button>
  </form>
</template>

<script>
import { required, minLength, requiredIf } from "@vuelidate/validators";
import { NON_DESTRUCTIVE } from "@/constants";
import { clone } from "@/utils";
import _ from "lodash";

export default {
  data() {
    return {
      tester: this.$store.state.user.id,
      observer: null,
      seam: null,
      station: [],
      air_pressure_vacuum_spark: "air_pressure",
      psi: [],
      time: [],
      startTime: null,
      endTime: null,
      test_passed: false,
      location: null,
      test_date: null,
      test_time: null,
      notes: null
    };
  },

  validations() {
    return {
      tester: {
        required
      },
      air_pressure_vacuum_spark: {
        required
      },
      seam: {
        required
      },
      station: {
        required,
        minLength: minLength(2)
      },
      test_passed: {
        requiredIf: requiredIf(
          () => this.air_pressure_vacuum_spark !== "air_pressure"
        )
      },
      psi: {
        requiredIf: requiredIf(
          () => this.air_pressure_vacuum_spark === "air_pressure"
        ),
        minLength: minLength(2)
      }
    };
  },

  // computed and updated are the same between all forms.
  // is there a better way to make this more DRY?
  computed: {
    isEdit: {
      get() {
        return !_.isEmpty(this.$store.state.entry);
      },
      set(val) {
        return val;
      }
    },
    observerProxy: {
      get() {
        let eo =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.observer
            ? this.$store.state.entry.content.observer
            : this.observer;
        this.observer = eo; // eslint-disable-line
        return eo;
      },
      set(val) {
        this.observer = val;
      }
    },
    seamProxy: {
      get() {
        let es =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.seam
            ? this.$store.state.entry.content.seam
            : this.seam;
        this.seam = es; // eslint-disable-line
        return es;
      },
      set(val) {
        this.seam = val;
      }
    },
    stationProxy: {
      get() {
        let est =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.station
            ? this.$store.state.entry.content.station
            : this.station;
        this.station = est; // eslint-disable-line
        return est;
      },
      set(val) {
        this.station = val;
      }
    },
    avsProxy: {
      get() {
        let eavs =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.air_pressure_vacuum_spark
            ? this.$store.state.entry.content.air_pressure_vacuum_spark
            : this.air_pressure_vacuum_spark;
        this.air_pressure_vacuum_spark = eavs; // eslint-disable-line
        return eavs;
      },
      set(val) {
        this.air_pressure_vacuum_spark = val;
      }
    },
    psiProxy: {
      get() {
        let epsi =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.psi
            ? this.$store.state.entry.content.psi
            : this.psi;
        this.psi = epsi; // eslint-disable-line
        return epsi;
      },
      set(val) {
        this.psi = val;
      }
    },
    startTimeProxy: {
      get() {
        let stime =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.time
            ? this.$store.state.entry.content.time
            : this.startTime;
        let st = false;
        if (!_.isNull(stime) && _.isArray(stime)) {
          st = stime.length > 1 ? stime[0] : stime;
        }
        this.startTime = st ? st : stime; // eslint-disable-line
        return st ? st : stime;
      },
      set(val) {
        this.startTime = val;
      }
    },
    endTimeProxy: {
      get() {
        let etime =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.time
            ? this.$store.state.entry.content.time
            : this.endTime;
        let et = false;
        if (!_.isNull(etime) && _.isArray(etime)) {
          et = etime.length > 1 ? etime[1] : etime;
        }
            this.endTime =  et ? et : etime; // eslint-disable-line
        return et ? et : etime;
      },
      set(val) {
        this.endTime = val;
      }
    },
    test_passedProxy: {
      get() {
        let epass =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.test_passed
            ? this.$store.state.entry.content.test_passed
            : this.test_passed;
        this.test_passed = epass; // eslint-disable-line
        return epass;
      },
      set(val) {
        this.test_passed = val;
      }
    },
    locationProxy: {
      get() {
        let el =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.location
            ? this.$store.state.entry.content.location
            : this.location;
        this.location = el; // eslint-disable-line
        return el;
      },
      set(val) {
        this.location = val;
      }
    },
    notesProxy: {
      get() {
        let en =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.notes
            ? this.$store.state.entry.content.notes
            : this.notes;
        this.notes = en; // eslint-disable-line
        return en;
      },
      set(val) {
        this.notes = val;
      }
    },
    test_dateProxy: {
      get() {
        let td =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.test_date
            ? this.$store.state.entry.content.test_date
            : this.test_date;
        this.test_date = td; // eslint-disable-line
        return td;
      },
      set(val) {
        this.test_date = val;
      }
    },
    test_timeProxy: {
      get() {
        let tt =
          !_.isEmpty(this.$store.state.entry) &&
          this.$store.state.entry.content.test_time
            ? this.$store.state.entry.content.test_time
            : this.test_time;
        this.test_time = tt; // eslint-disable-line
        return tt;
      },
      set(val) {
        this.test_time = val;
      }
    },
    disabled() {
      // return this.isEdit ? false : !this.$v.$dirty || this.$v.$errors.length;
      return false;
    }
  },
  updated() {
    this.$v.$touch();
  },

  methods: {
    submit() {
      // When an air pressure test is performed the air channel between the welds is pressurized to 30 psi.
      // The QC will wait 1-2 minutes for it to settle, the material may expand due to heat/weather which may result in a slight loss of pressure.
      // They will re-pressurize the seam back up to 30 psi and then they will wait 5 minutes.
      // They are not allowed to touch anything during that 5 minutes.
      // If it drops less than 3 lbs the test passes
      // If it drops more than 3 lbs the test fails
      // Example:
      // If it drops from 30 psi to 27 psi it passes
      // If it drops from 30 psi to 26 psi it fails
      var vm = this;
      let data = clone(this.$data);
      data.edit = this.isEdit;
      data.type = NON_DESTRUCTIVE;
      if (this.air_pressure_vacuum_spark !== "air_pressure") {
        delete data.psi;
      } else {
        data.time = [this.startTime, this.endTime];
        let result = data.psi[1] - data.psi[0];
        data.test_passed = result >= 4 ? false : true;
      }
      delete data.startTime;
      delete data.endTime;
      // if (this.isEdit) {
      //   console.log('set tempId', this.$store.state.entry.content.date);
      //   console.log('set var tempId', this.tempId);
      //   data.tempId = this.$store.state.entry.content.date;
      // }
      this.$store.dispatch("entry", data).then(() => {
        vm.$store.commit("clearEntry");
      });
    }
  }
};
</script>

<style lang="postcss">
.form\:non-destructive {
  & .non-destructive-seam {
    max-width: 165px;
  }
  & .non-destructive-station {
    width: calc(50% - (var(--form-group-gap) / 2));
  }
  & .non-destructive-checkboxes .field {
    --form-group-gap: 2rem;
    flex-basis: auto;
  }
}
</style>
