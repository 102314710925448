export const WELDS = "welds";
export const NON_DESTRUCTIVE = "non-destructive";
export const DESTRUCTIVE = "destructive";
export const PLACEMENT = "placement";
export const SEAMING = "seaming";
export const REPAIRS = "repairs";
export const SIGNOFF = "signoff";
export const SUBGRADESIGNOFF = "subgradesignoff";
export const COMPLETE = "complete";
// export const INITIAL_SIGNOFF = 'initial-signoff';

export const DOCUMENTATION = Object.freeze({
  [WELDS]: "Trial Welds",
  [NON_DESTRUCTIVE]: "Non-Destructive Tests",
  [DESTRUCTIVE]: "Destructive Tests",
  [PLACEMENT]: "Panel Placement",
  [SEAMING]: "Panel Seaming",
  [REPAIRS]: "Repair Report",
  [SUBGRADESIGNOFF]: "Subgrade Sign-Off",
  [SIGNOFF]: "Customer Sign-Off",
  [COMPLETE]: "Complete Layer"
});

//this has been moved to an API but kept the constant as a fallback if the first load fails
export const MATERIALS = Object.freeze({
  "40-mil HDPE": {
    wedge_peel: 60,
    wedge_shear: 80,
    extrusion_peel: 52,
    extrusion_shear: 80
  },
  "40-mil LLDPE": {
    wedge_peel: 50,
    wedge_shear: 60,
    extrusion_peel: 44,
    extrusion_shear: 60
  },
  "60-mil HDPE": {
    wedge_peel: 91,
    wedge_shear: 120,
    extrusion_peel: 78,
    extrusion_shear: 120
  },
  "60-mil LLDPE": {
    wedge_peel: 75,
    wedge_shear: 90,
    extrusion_peel: 66,
    extrusion_shear: 90
  },
  "80-mil HDPE": {
    wedge_peel: 121,
    wedge_shear: 160,
    extrusion_peel: 104,
    extrusion_shear: 160
  },
  "80-mil LLDPE": {
    wedge_peel: 100,
    wedge_shear: 120,
    extrusion_peel: 88,
    extrusion_shear: 120
  },
  "100-mil HDPE": {
    wedge_peel: 151,
    wedge_shear: 200,
    extrusion_peel: 130,
    extrusion_shear: 200
  },
  "100-mil LLDPE": {
    wedge_peel: 125,
    wedge_shear: 150,
    extrusion_peel: 114,
    extrusion_shear: 150
  }
});
