<template>
  <teleport to="body">
    <transition>
      <div v-if="open" :class="['modal', className]">
        <div class="modal-outer">
          <div class="modal-inner" @click="onClick">
            <div class="modal-content" :style="{ width: width + 'px' }">
              <button
                v-if="closeButton"
                type="button"
                class="modal-close"
                @click="close"
              >
                Close
              </button>
              <slot :close="close" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],

  props: {
    open: Boolean,
    width: Number,
    className: String,
    closeOnClick: {
      type: Boolean,
      default: true
    },
    closeButton: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    onClick(event) {
      this.closeOnClick && event.target.matches(".modal-inner") && this.close();
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style>
.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: opacity 250ms;
}
.modal.v-enter-from,
.modal.v-leave-to {
  opacity: 0;
}
.modal-outer {
  display: table;
  width: 100%;
  height: 100%;
}
.modal-inner {
  display: table-cell;
  vertical-align: middle;
}
.modal-content {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 3em;
  background-color: #fff;
}
.modal-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  outline: none;
  background: #ccc;
  font-size: 0;
}
</style>
