<template>
  <Loading v-if="isLoading" />
  <header class="header">
    <div class="w-100 flex items-center justify-between">
      <div class="logo">
        <img src="/img/logo.png" width="77" height="50" />
        <span>IEC Field Docs</span>
      </div>
      <button type="button" @click="$refs.modalName.openModal()">
        <Avatar :user="user" />
      </button>
    </div>
    <h1>App Info</h1>
    <logInModal ref="modalName" />
    <nav class="info-nav">
      <!-- <a @click="$router.go()">Force Refresh</a> -->
      <a @click="$router.go(-1)">Back to Previous</a>
      <router-link :to="{ name: 'projects' }">Projects List</router-link>
    </nav>
  </header>
  <div class="info">
    <section>
      <table>
        <tr>
          <th>Build Number</th>
          <td>{{ version }}</td>
          <td><button @click="$router.go()">Force Refresh</button></td>
        </tr>
        <tr>
          <th>Working Memory</th>
          <td colspan="2">{{ localUsage }} /5MB</td>
        </tr>
        <tr>
          <th>Storage Memory</th>
          <td colspan="2">{{ usage }}</td>
        </tr>
        <tr>
          <th>Storage Persistence</th>
          <td colspan="2">{{ persistence }}</td>
        </tr>
        <tr>
          <th>Total Entries</th>
          <td colspan="2">
            <span v-if="typeof entryList !== 'undefined'">{{
              entryList.length
            }}</span>
          </td>
        </tr>
      </table>
    </section>
    <section>
      <div class="w-100 flex items-center justify-between">
        <h2>Pending Entries</h2>
        <button
          :class="[hasPending && connected ? '' : 'disabled', 'postNow']"
          @click="postNow()"
        >
          Post Entries
        </button>
      </div>
      <ul v-if="!hasPending">
        <li>No Pending Entries</li>
      </ul>
      <ul v-else>
        <li v-for="(pending, index) in pendingList" :key="'p' + index">
          <div>Project: {{ pending.project_id }}</div>
          <div>Site: {{ pending.site_id }}</div>
          <div>Layer: {{ pending.layer_id }}</div>
          <div>{{ pending.type }}</div>
          <div>{{ new Date(pending.date).toString().substr(4, 20) }}</div>
        </li>
      </ul>
    </section>
    <section>
      <h2>Posted Entries</h2>
      <ul v-if="!hasPosted">
        <li>No Posted Entries</li>
      </ul>
      <ul v-else>
        <li v-for="(posted, index) in postedList" :key="'s' + index">
          <div>Project: {{ posted.project_id }}</div>
          <div>Site: {{ posted.site_id }}</div>
          <div>Layer: {{ posted.layer_id }}</div>
          <div>{{ posted.type }}</div>
          <div>{{ new Date(posted.date).toString().substr(4, 20) }}</div>
        </li>
      </ul>
    </section>
    <!-- <section>
      <h2>All Stored Entries (Pending & Posted)</h2>
      <ul>
        <li v-for="(entry, index) in entryList" :key="'a' + index">
          <div>Date: {{ new Date(entry.date).toString().substr(4, 11) }}</div>
          <div>Type: {{ entry.type }}</div>
          <div>Project: {{ entry.project_id }}</div>
          <div>Site: {{ entry.site_id }}</div>
          <div>Layer: {{ entry.layer_id }}</div>
        </li>
      </ul>
    </section> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import logInModal from "@/components/LoginModal";
import Loading from "@/components/Loading";
import _ from "lodash";

export default {
  components: { logInModal, Loading },
  data: () => ({
    isLoading: true,
    allIsLoading: true,
    pendingIsLoading: true,
    postedIsLoading: true,
    entryList: [],
    pendingList: [],
    postedList: [],
    usage: "",
    persistence: ""
  }),
  computed: {
    ...mapGetters(["connected"]),
    ...mapState({
      user: state => state.user,
      projects: state => state.projects.projects
    }),
    localUsage() {
      let _lsTotal = 0,
        _xLen,
        _x;
      for (_x in window.localStorage) {
        /* eslint-disable */
        if (!window.localStorage.hasOwnProperty(_x)) {
          continue;
        }
        /* eslint-enable */
        _xLen = (window.localStorage[_x].length + _x.length) * 2;
        _lsTotal += _xLen;
      }
      return (_lsTotal / 1024).toFixed(2) + "KB";
    },
    version() {
      // should this be created from a git tag????
      if (process.env.NODE_ENV === "production") {
        return document
          .querySelector("body script:last-of-type")
          .src.split(".")[3];
      } else {
        return document
          .querySelector("body script:last-child")
          .src.split(".")[1];
      }
    },
    hasPending() {
      return !_.isEmpty(this.pendingList);
    },
    hasPosted() {
      return !_.isEmpty(this.postedList);
    }
  },
  methods: {
    async postNow() {
      if (!this.hasPending || !this.connected) {
        window.confirm(
          "This functionality is currently unavailable. It requires an internet connection and pending entries."
        );
      } else {
        this.isLoading = true;
        await this.$store
          .dispatch("pushNow")
          .then(() => {
            Array.prototype.push.apply(this.postedList, this.pendingList);
            this.pendingList = [];
          })
          .finally((this.isLoading = false));
      }
    }
  },
  beforeMount() {
    // this.$store.dispatch("getProjects", this.user.id);
  },
  mounted() {
    this.$store
      .dispatch("getAllEntries")
      .then(response => {
        if (typeof response !== "undefined" && response.length > 0) {
          this.entryList = _.cloneDeep(response);
        }
      })
      .finally((this.allIsLoading = false));

    this.$store
      .dispatch("getSavedEntries")
      .then(response => {
        if (typeof response !== "undefined" && response.length > 0) {
          this.postedList = _.cloneDeep(response);
        }
      })
      .finally((this.postedIsLoading = false));

    this.$store
      .dispatch("getPendingEntries")
      .then(response => {
        if (typeof response !== "undefined" && response.length > 0) {
          this.pendingList = _.cloneDeep(response);
        }
      })
      .finally((this.pendingIsLoading = false));

    if ("storage" in navigator && "estimate" in navigator.storage) {
      navigator.storage.estimate().then(estimate => {
        const percentUsed = Math.round(
          (estimate.usage / estimate.quota) * 100
        ).toFixed(2);
        const usageInMib = Math.round(estimate.usage / (1024 * 1024));
        const quotaInMib = Math.round(estimate.quota / (1024 * 1024));
        this.usage =
          usageInMib + "MB / " + quotaInMib + "MB (" + percentUsed + "%)";
      });
    }

    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persisted().then(persistent => {
        if (persistent) {
          this.persistence = "Cleared only by explicit user action";
        } else {
          this.persistence = "Cleared by the UA under storage pressure.";
        }
      });
    }

    // let sites = _.flattenDeep(this.$store.state.projects.projects[0].sites);
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.isLoading = false;
    });
  },
  created() {
    // this.$store.dispatch("resetProject");
  }
};
</script>

<style scoped lang="postcss">
.header {
  padding: 24px 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--color-gray-8);
  color: white;

  & > h1 {
    font-size: var(--text-xl);
    font-weight: md;
    margin: 0;
    margin-top: 2em;
    color: white;
    align-self: flex-start;
  }
  & .info-nav {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    & a {
      flex-basis: 25%;
      text-align: center;
      background: white;
      color: var(--color-gray-7);
      padding: 0.5rem 1rem;
      border-radius: 0.25rem 0.25rem 0 0;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    }
    & a:first-of-type {
      background: var(--color-gray-2);
      box-shadow: none;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  font-size: var(--text-lg);
  font-weight: 700;
  & img {
    margin-right: 1rem;
  }
}

.info {
  height: 100%;
  padding: 0 24px;
  & table {
    width: 100%;
    margin: 2rem 0;
    background: white;
    & button {
      position: relative;
      z-index: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      background-color: var(--color-gray-8);
      border-radius: var(--border-radius-sm);
      box-shadow: var(--shadow-md);
      color: #fff;
      line-height: 1;
      text-decoration: none;
      user-select: none;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.25s;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, #000, #fff);
        opacity: 0.06;
        transition: inherit;
        mix-blend-mode: overlay;
      }
      &:focus,
      &:active {
        background-color: var(--color-green-1);
        box-shadow: none;
      }
      &:disabled {
        background-color: var(--color-gray-4);
        pointer-events: none;
        box-shadow: none;
      }
      &:disabled:before {
        opacity: 0;
      }
    }
    & tr {
      & th,
      & td {
        padding: 0.5rem;
      }
      & th {
        text-align: left;
      }
      & td {
        text-align: right;
      }
    }
    & tr:nth-child(even) {
      background: var(--color-gray-2);
    }
  }
  & section {
    padding: 1rem 0 2rem;
  }
  & ul {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;
    width: 100%;
    & li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      word-break: break-all;
      padding: 0.25rem;
      border-bottom: solid 1px var(--color-gray-2);
      background: white;
      & div:nth-child(4) {
        min-width: 12rem;
      }
    }
  }
}
button.postNow {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: var(--color-gray-8);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-md);
  color: white;
  line-height: 1;
  text-decoration: none;
  user-select: none;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.25s;
}
button.postNow.disabled {
  background-color: var(--color-gray-4);
  box-shadow: none;
}
</style>
