<template>
  <div class="field field:switch">
    <!-- <label>Welder</label> -->
    <fieldset class="input">
      <div>
        <input
          id="switch-extrusion"
          type="radio"
          name="welder"
          :checked="modelValue === 'extrusion'"
          @change="$emit('update:modelValue', 'extrusion')"
        />
        <label for="switch-extrusion">Extrusion</label>
      </div>
      <div>
        <input
          id="switch-wedge"
          type="radio"
          name="welder"
          :checked="modelValue === 'wedge'"
          @change="$emit('update:modelValue', 'wedge')"
        />
        <label for="switch-wedge">Wedge</label>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: String
  }
};
</script>

<style>
.field\:switch {
  & fieldset {
    display: flex;
    height: var(--hack-input-height);
    padding: 4px;
  }
  & fieldset > div {
    width: 50%;
  }
  & fieldset input {
    position: absolute;
    opacity: 0;
  }
  & fieldset label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    color: var(--color-gray-6);
  }
  & fieldset input:checked + label {
    color: #fff;
    background-color: var(--color-green-3);
  }
}
</style>
