import "@/styles/main.css";

import { createApp } from "vue";
import { VuelidatePlugin as validation } from "@vuelidate/core";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";

import runtime from "serviceworker-webpack-plugin/lib/runtime";

if ("serviceWorker" in navigator) {
  runtime.register();
}

import Icons from "@/components/Icons.vue";
import NetworkStatus from "@/components/Network.vue";
import Loading from "@/components/Loading";
import Avatar from "@/components/Avatar";
import Marker from "@/components/Marker";
import Button from "@/components/Button";
import Icon from "@/components/Icon";
import Indicator from "@/components/Indicator";
import Modal from "@/components/Modal";
import Checkbox from "@/components/fields/Checkbox";
import Date from "@/components/fields/Date";
import Employee from "@/components/fields/Employee";
import Input from "@/components/fields/Input";
import Range from "@/components/fields/Range";
import Signature from "@/components/fields/Signature";
import Switch from "@/components/fields/Switch";
import VSSwitch from "@/components/fields/VSSwitch";
import AVSSwitch from "@/components/fields/AVSSwitch";
import Tests from "@/components/fields/Tests";
import Text from "@/components/fields/Text";
import Time from "@/components/fields/Time";

const app = createApp(App);

// app.config.devtools = true;

app.component("Icons", Icons);
app.component("NetworkStatus", NetworkStatus);
app.component("Loading", Loading);
app.component("Marker", Marker);
app.component("Avatar", Avatar);
app.component("Button", Button);
app.component("Icon", Icon);
app.component("Indicator", Indicator);
app.component("Modal", Modal);

app.component("Checkbox", Checkbox);
app.component("Date", Date);
app.component("Employee", Employee);
app.component("Input", Input);
app.component("Range", Range);
app.component("Signature", Signature);
app.component("Switch", Switch);
app.component("VSSwitch", VSSwitch);
app.component("AVSSwitch", AVSSwitch);
app.component("Tests", Tests);
app.component("Text", Text);
app.component("Time", Time);

app
  .use(store)
  .use(router)
  .use(validation)
  .mount("#app");
