<template>
  <div class="field field:datetime">
    <label v-if="label">{{ label }}</label>
    <fieldset>
      <select
        :class="{ 'has-value': !!month }"
        v-model="month"
        @change="update(), select('day')"
      >
        <option v-for="(month, i) in months" :value="i" :key="i">{{
          month
        }}</option>
      </select>
      <input
        ref="day"
        type="text"
        inputmode="numeric"
        maxlength="2"
        placeholder="DD"
        :value="day"
        @input="input($event, 'day')"
        @change="update"
      />
      <input
        ref="year"
        type="text"
        inputmode="numeric"
        maxlength="4"
        placeholder="YYYY"
        :value="year"
        @input="input($event, 'year')"
        @change="update"
      />
    </fieldset>
  </div>
</template>

<script>
import { clamp } from "@/utils";

export default {
  emits: ["update:modelValue"],

  props: {
    label: String,
    modelValue: String
  },

  data() {
    const date = new Date();
    return {
      month: date.getMonth(),
      day: date.getDate(),
      year: date.getFullYear()
    };
  },

  computed: {
    months: () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
  },

  created() {
    this.update();
  },

  methods: {
    select(name) {
      const input = this.$refs[name];
      input && input.select();
    },

    input(event, key) {
      const input = event.target;
      let value = (input.value = input.value.replace(/\D/g, ""));

      if (key === "day") value = clamp(value, 0, 31);
      if (key === "year") value = clamp(value, 0, new Date().getFullYear());
      this[key] = value;

      if (value.length === input.maxLength) {
        const steps = ["month", "day", "year"];
        this.select(steps[steps.indexOf(key) + 1]);
      }
    },

    update() {
      const yyyy = this.year;
      const mm = String(this.month + 1).padStart(2, "0");
      const dd = String(this.day).padStart(2, "0");
      this.$emit("update:modelValue", `${yyyy}-${mm}-${dd}`);
    }
  }
};
</script>

<style>
.field.field\:datetime.placement-date {
  flex-grow: 1;
}
.field\:datetime {
  & input:focus,
  & select:focus {
    position: relative;
    z-index: 1;
  }
  & fieldset {
    display: flex;
  }
  & fieldset > select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & fieldset > :nth-child(2) {
    flex: 0 0 70px;
    margin-left: calc(var(--input-border-width) * -1);
    margin-right: calc(var(--input-border-width) * -1);
    border-radius: 0;
    text-align: center;
  }
  & fieldset > :nth-child(3) {
    flex: 0 0 90px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: center;
  }
}
</style>
