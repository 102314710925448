<template>
  <div class="project">
    <Loading v-if="isLoading" />

    <div v-if="hasProject">
      <Header :project="{ client: project.customer, location: project.name }" />

      <div class="pad pt-0">
        <div class="layer-nav mb-3">
          <select
            v-model="selectedSite"
            class="select"
            @change="updateSite($event)"
          >
            <option
              v-for="site in project.sites"
              :key="site.name"
              :value="site.name"
              >{{ site.name }}</option
            >
          </select>
        </div>
      </div>

      <div v-if="!isLoading" :v-model="site" class="layers">
        <div>
          <h2>{{ site.name }}</h2>
          <p>{{ site.location }}</p>

          <ul v-if="site.layers.length === 0" class="layer-card">
            <li>Location has no layers</li>
          </ul>
          <ul v-else>
            <li v-for="layer in site.layers" :key="layer.id" class="layer-card">
              <router-link
                :to="{
                  name: 'layer',
                  params: {
                    project: project.id,
                    layer: layer.id,
                    view: 'welds'
                  }
                }"
              >
                <Marker v-if="layer.is_completed" checked />
                <Marker v-else :type="layer.type" :color="layer.color" />
                <div class="details">
                  <h3>{{ layer.name }}</h3>
                  <p>{{ layer.material }}</p>
                </div>
              </router-link>
              <button
                :class="[connected ? '' : 'disabled', 'downloadPDF']"
                @click="downloadPDF(layer.id)"
              >
                Download PDF
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Loading from "@/components/Loading";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";

export default {
  components: { Header, Loading },
  data: () => ({
    isLoading: true
  }),
  computed: {
    ...mapGetters(["hasProject", "connected"]),
    ...mapState({
      user: state => state.user,
      project: state => state.project
      // site: state => state.site
    }),
    selectedSite: {
      get() {
        return _.isNull(this.$store.state.site)
          ? this.$store.state.project.sites[0].name
          : this.$store.state.site.name;
      },
      set(val) {
        this.$store.dispatch("getSite", val);
      }
    },
    site: {
      get() {
        return _.isNull(this.$store.state.site)
          ? this.$store.state.project.sites[0]
          : this.$store.state.site;
      },
      set(val) {
        this.$store.commit("setSite", val);
      }
    }
  },
  methods: {
    updateSite(event) {
      this.$store.dispatch("getSite", event.target.value);
    },
    downloadPDF(id) {
      if (!this.connected) {
        window.confirm(
          "This functionality is currently unavailable. It requires an internet connection."
        );
      } else {
        window.open("https://app.ieccovers.com/api/admin/layer_pdf/" + id);
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been rendered
      this.isLoading = false;
    });
  },
  created() {
    if (
      _.isNull(this.$store.state.project) ||
      (!_.isNull(this.$store.state.project) &&
        parseInt(this.$store.state.project.id) !==
          parseInt(this.$route.params.project))
    ) {
      this.$store
        .dispatch("getProjectById", this.$route.params.project)
        .then(() => {
          this.isLoading = false;
        });
    }
    // if(_.isNull(this.$store.state.site)){
    //   // this.$store.dispatch("getSite", this.$store.state.project.sites[0].name);
    // }
  },
  beforeMount() {
    // console.log('_.isNull(this.$store.state.project)', _.isNull(this.$store.state.project));
    // console.log('!_.isNull(this.$store.state.project) && this.$store.state.project.id !== this.$route.params.project', !_.isNull(this.$store.state.project) && this.$store.state.project.id !== this.$route.params.project);
    // if (_.isNull(this.$store.state.project) ||
    // (!_.isNull(this.$store.state.project) && this.$store.state.project.id !== this.$route.params.project)
    //
    // ) {
    //   // if ( (_.isNull(this.$store.state.project)) || (!_.isNull(this.$store.state.project) && this.$store.state.project.id !== this.$route.params.project )) {
    //   // if (_.isNull(this.$store.state.project) && this.$store.state.project.id !== this.$route.params.project) {
    // // this.$store.dispatch("getProject", this.$route.params.project);
    //   this.$store.dispatch("getProjectById", this.$route.params.project).then(() => {
    //       this.isLoading = false;
    //   });
    // }
    // this.$store.dispatch("getSite", _.isNull(this.$store.state.site)
    //   ? this.$store.state.projects.projects[0].sites[0]
    //   : this.$store.state.site);
    // let sites = _.flattenDeep(this.$store.state.projects.projects[0].sites);
  }
};
</script>

<style scoped lang="postcss">
.project {
  background: var(--color-gray-2);
  height: 100%;
}

.locations {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 64px;
}

.layers {
  padding: 0 50px;

  & ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.title {
  color: var(--color-gray-6);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.layer-card {
  position: relative;
  background: white;
  padding: 16px;

  & > a {
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
  }
  & button {
    position: absolute;
    top: 2rem;
    right: 1rem;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: var(--color-gray-8);
    border-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-md);
    color: #fff;
    line-height: 1;
    text-decoration: none;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.25s;
  }
  & button.downloadPDF.disabled {
    background-color: var(--color-gray-4);
    box-shadow: none;
  }
}

.details {
  display: flex;
  flex-direction: column;
  color: var(--color-gray-5);

  & > * {
    margin: 0;
  }
}
</style>
